<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="$t(userId === 0 ? 'user.addNewUser' : 'user.userInfoTitle')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <!-- <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  :rules="[requiredValue]"
                  v-model="username"
                  :label="$t('login.username')"
                  :name="$t('login.username')"
                  prepend-icon="mdi-account"
                  type="text"
                  class="required"
                />
              </v-flex> -->
              <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  :rules="[requiredValue]"
                  v-model="email"
                  :label="$t('common.email')"
                  :name="$t('common.email')"
                  :disabled="userId !== 0"
                  prepend-icon="mdi-email"
                  type="text"
                  class="required"
                />
              </v-flex>
              <v-flex
                v-if="userId===0"
                xs12
                sm8
                md6>
                <v-text-field
                  id="password"
                  :rules="[requiredValue]"
                  v-model="password"
                  :label="$t('login.password')"
                  :name="$t('login.password')"
                  prepend-icon="mdi-lock"
                  type="password"
                  class="required"
                  loading
                  @input="validatePassword(password)"
                >
                  <template v-slot:progress>
                    <v-progress-linear
                      :value="progress"
                      :color="color"
                      absolute
                      height="5"
                    />
                  </template>
                </v-text-field>
                <div
                  v-show="passwordTyped"
                  class="lnu_container">
                  <p :class="{ got_number: containNumber }">
                    {{ $t("user.digit") }}
                  </p>
                  <p :class="{ got_lowercase: containLowercase }">
                    {{ $t("user.lowercase") }}
                  </p>
                  <p :class="{ got_uppercase: containUppercase }">
                    {{ $t("user.uppercase") }}
                  </p>
                </div>
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  :rules="[requiredValue]"
                  v-model="fullName"
                  :label="$t('user.fullname')"
                  :name="$t('user.fullname')"
                  prepend-icon="mdi-account-group"
                  type="text"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="birtDateFormatted"
                      :label="$t('user.dob')"
                      persistent-hint
                      prepend-icon="mdi-cake"
                      @blur="date = parseDate(birtDateFormatted)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="birtDate"
                    no-title
                    @input="menu = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  :rules="[requiredValue]"
                  v-model="phone"
                  :label="$t('user.phone')"
                  :name="$t('user.phone')"
                  :disabled="!isAdmin() && !isSuperAdmin() && !isAccountant() && (groupId === sysGroupEnum.CUSTOMER && userId !== 0)"
                  prepend-icon="mdi-cellphone-iphone"
                  type="number"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-select
                  v-model="gender"
                  :items="genderList"
                  :label="$t('user.gender')"
                  prepend-icon="mdi-gender-transgender"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
              <v-flex
                xs12
                md6>
                <v-select
                  v-model="groupId"
                  :items="groupList"
                  :label="$t('decentralizationGroup.privilege')"
                >
                  <template
                    slot="selection"
                    slot-scope="data"
                    chips
                  >
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          v-if="isAdmin() || isSuperAdmin() || isAccountant() || (isModerator() && groupId === sysGroupEnum.CUSTOMER)"
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-dialog>
</template>
<script>
import functionUtils from 'utils/functionUtils'
import stringUtils from 'utils/stringUtils'
import dateUtils from 'utils/dateUtils'
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import sysGroupEnum from 'enum/sysGroupEnum'
import RoleType from 'enum/roleType'

export default {
  data () {
    return {
      valid: false,
      isShowModal: false,
      birtDate: null,
      birtDateFormatted: null,
      genderList: stringUtils.GenderList,
      username: null,
      password: '',
      birthDate: null,
      gender: null,
      fullName: null,
      phone: null,
      menu: false,
      currentPage: 1,
      email: null,
      isLoading: false,
      groupList: [],
      groupId: 3,
      userId: 0,
      adminGroup: stringUtils.adminGroup,
      customerGroup: stringUtils.customerGroup,
      //
      passwordLength: 0,
      passwordTyped: false,
      containLowercase: false,
      containUppercase: false,
      containNumber: false,
      validPasswordLength: false,
      validPassword: false,
      sysGroupEnum: sysGroupEnum
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.birtDate)
    },
    progress () {
      return Math.min(100, this.password.length * 12.5)
    },
    color () {
      return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
    },
    ...mapGetters(['GET_SNACK_BAR', 'GET_CURRENT_ACCOUNT'])
  },
  watch: {
    birtDate (val) {
      this.birtDateFormatted = this.formatDate(this.birtDate)
    }
  },
  mounted () {},
  methods: {
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.hasSystemRole(RoleType.ADMIN, this.GET_CURRENT_ACCOUNT)
    },
    isModerator: function () {
      return functionUtils.hasSystemRole(RoleType.MODERATOR, this.GET_CURRENT_ACCOUNT)
    },
    // Check Accountant
    isAccountant: function () {
      return functionUtils.hasSystemRole(RoleType.ACCOUNTANT, this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Required value
     */
    requiredValue (value) {
      if (value !== null && value !== undefined && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    /**
     * Format date
     */
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    /**
     * Parse date
     */
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
     * Show modal
     */
    onShowModal: function (currentPage, isAdmin) {
      this.isShowModal = true
      this.currentPage = currentPage
      this.userId = 0
      this.groupList = isAdmin ? this.adminGroup : this.customerGroup
      this.groupId = isAdmin ? sysGroupEnum.ADMIN : sysGroupEnum.CUSTOMER
      //
      this.fullName = null
      this.email = null
      this.phone = null
      this.birtDateFormatted = null
      this.birtDate = null
      this.gender = null
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    onShowModalUpdateUser: function (currentPage, isAdmin, userData) {
      this.isShowModal = true
      this.currentPage = currentPage
      this.groupList = isAdmin ? this.adminGroup : this.customerGroup
      this.userId = userData && userData.id
      this.fullName = userData && userData.fullname
      this.email = userData && userData.email.trim().toLowerCase()
      this.phone = userData && userData.phone
      this.birtDateFormatted = userData && userData.dob
      this.birtDate = !functionUtils.validateStringIsNull(this.birtDateFormatted)
        ? dateUtils.formatDate(this.birtDateFormatted, 'DD/MM/YYYY', 'YYYY-MM-DD') : null
      this.groupId = userData && userData.systemRoleId
      this.gender = userData && userData.gender
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        this.snackbar = true
        if (!functionUtils.validateEmail(this.email.trim())) {
          this.ON_SHOW_TOAST({
            'message': this.$t('register.emailInvalidate'),
            'styletype': ToastType.ERROR
          })
        } else if (!functionUtils.validatePhone(this.phone)) {
          this.ON_SHOW_TOAST({
            'message': this.$t('register.phoneInvalidate'),
            'styletype': ToastType.ERROR
          })
        } else if (!this.validPassword && this.userId === 0) {
          this.ON_SHOW_TOAST({
            'message': this.$t('user.ChangePasswordModal.weakPassError'),
            'styletype': ToastType.ERROR
          })
        } else {
          let lastName = functionUtils.getFirstLastName(this.fullName).lastName
          let firstName = functionUtils.getFirstLastName(this.fullName).firstName
          let filter = {
            id: this.userId,
            username: this.email.trim().toLowerCase(),
            email: this.email.trim().toLowerCase(),
            // password: this.password,
            lastName: lastName,
            firstName: firstName,
            birthDate: !functionUtils.validateStringIsNull(this.birtDateFormatted)
              ? dateUtils.formatDate(this.birtDateFormatted, 'DD/MM/YYYY', 'YYYY-MM-DD') : null,
            phone: this.phone,
            gender: this.gender,
            groupId: this.groupId
          }
          this.isLoading = true
          if (this.userId === 0) {
            filter['password'] = this.password
            this.REGISTER_ACCOUNT(filter).then(
              function () {
                this.isLoading = false
                this.isShowModal = false
                this.$emit('getApiUserList', true)
              }.bind(this)
            ).catch(
              function (error) {
                this.isLoading = false
                if (!functionUtils.isNull(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    'message': this.$t(`${errorText}`),
                    styletype: ToastType.ERROR
                  })
                } else if (!functionUtils.isNull(error.response.data.message) && error.response.data.message.includes('(identification)')) {
                  this.ON_SHOW_TOAST({
                    'message': this.$t('users.errorText.indentAlreadyExist'),
                    styletype: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    'message': this.$t('login.somethingWentWrong'),
                    styletype: ToastType.ERROR
                  })
                }
              }.bind(this)
            )
          } else {
            this.UPDATE_USER_PROFILE(filter).then(
              function () {
                this.isLoading = false
                this.isShowModal = false
                this.$emit('getApiUserList', true)
              }.bind(this)
            ).catch(
              function (error) {
                this.isLoading = false
                if (!functionUtils.isNull(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    'message': this.$t(`${errorText}`),
                    styletype: ToastType.ERROR
                  })
                } else if (!functionUtils.isNull(error.response.data.message) && error.response.data.message.includes('(identification)')) {
                  this.ON_SHOW_TOAST({
                    'message': this.$t('users.errorText.indentAlreadyExist'),
                    styletype: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    'message': this.$t('login.somethingWentWrong'),
                    styletype: ToastType.ERROR
                  })
                }
              }.bind(this)
            )
          }
        }
      }
    },
    validatePassword: function (password) {
      this.passwordLength = password.length

      if (this.passwordLength > 7) {
        this.validPasswordLength = true
      } else {
        this.validPasswordLength = false
      }

      if (this.passwordLength > 0) {
        this.passwordTyped = true
      } else {
        this.passwordTyped = false
      }

      this.containLowercase = /[a-z]/.test(password)
      this.containUppercase = /[A-Z]/.test(password)
      this.containNumber = /\d/.test(password)

      if (
        this.containLowercase === true &&
        this.containUppercase === true &&
        this.containNumber === true &&
        this.validPasswordLength === true
      ) {
        this.validPassword = true
      } else {
        this.validPassword = false
      }
    },
    ...mapActions(['ON_SHOW_TOAST', 'REGISTER_ACCOUNT', 'UPDATE_USER_PROFILE'])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
//
.lnu_container {
  display: block;
  margin: 0px auto;
  margin-bottom: 0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}
.lnu_container p {
  width: 30%;
  height: auto;
  font-size: 12px !important;
  line-height: 1.2;
  text-align: center;
  border-radius: 2px;
  color: rgba(71, 87, 98, 0.8);
  background: linear-gradient(to right, #06a65a 50%, #eee 50%);
  background-size: 200% 100%;
  background-position: right;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.got_lowercase,
.got_uppercase,
.got_number {
  background-position: left !important;
  color: rgba(255, 255, 255, 0.9) !important;
}
.show_password_length {
  display: block !important;
}
</style>
