<template>
  <div class="avatar-cropper">
    <div
      v-if="dataUrl"
      class="avatar-cropper-overlay"
    >
      <div class="avatar-cropper-mark">
        <a
          class="avatar-cropper-close"
          href="javascript:;"
          @click="destroy"
        >&times;</a>
      </div>
      <div class="avatar-cropper-container">
        <div class="avatar-cropper-image-container">
          <img
            ref="img"
            :src="dataUrl"
            alt
            @load.stop="createCropper"
          >
        </div>
        <div class="avatar-cropper-footer">
          <button
            class="avatar-cropper-btn"
            @click.stop.prevent="destroy"
            v-text="labels.cancel"
          >Cancel</button>
          <button
            class="avatar-cropper-btn"
            @click.stop.prevent="submit"
            v-text="labels.submit"
          >Submit</button>
        </div>
      </div>
    </div>
    <input
      ref="input"
      :accept="mimes"
      class="avatar-cropper-img-input"
      type="file"
    >
  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'AvatarCropper',
  props: {
    trigger: {
      type: [String, Element],
      required: true
    },
    uploadHandler: {
      type: Function,
      default () {
        return null
      }
    },
    uploadUrl: {
      type: String,
      default: ''
    },
    requestMethod: {
      type: String,
      default: 'POST'
    },
    uploadHeaders: {
      type: Object,
      default () {
        return {}
      }
    },
    uploadFormName: {
      type: String,
      default: 'file'
    },
    uploadFormData: {
      type: Object,
      default () {
        return {}
      }
    },
    cropperOptions: {
      type: Object,
      default () {
        return {
          aspectRatio: 1,
          autoCropArea: 1,
          viewMode: 1,
          movable: false,
          zoomable: false
        }
      }
    },
    outputOptions: {
      type: Object,
      default () {
        return {}
      }
    },
    outputMime: {
      type: String,
      default: null
    },
    outputQuality: {
      type: Number,
      default: 0.9
    },
    mimes: {
      type: String,
      default: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon'
    },
    labels: {
      type: Object,
      default () {
        return {
          submit: 'Submit',
          cancel: 'Cancel'
        }
      }
    }
  },
  data () {
    return {
      cropper: undefined,
      dataUrl: undefined,
      filename: undefined,
      mediaServer: {
        domain: null,
        token: null
      }
    }
  },
  computed: {
    ...mapGetters(['GET_TOKEN_MEDIA_SERVER_DATA', 'GET_ACCOUNT_DATA'])
  },
  watch: {
    GET_TOKEN_MEDIA_SERVER_DATA: function () {
      this.mediaServer.token = this.GET_TOKEN_MEDIA_SERVER_DATA.data.token
      this.mediaServer.domain = this.GET_TOKEN_MEDIA_SERVER_DATA.data.domain
    }
  },
  mounted () {
    this.GET_TOKEN_MEDIA_SERVER()
    // listen for click event on trigger
    let trigger =
      typeof this.trigger === 'object'
        ? this.trigger
        : document.querySelector(this.trigger)
    if (!trigger) {
      this.$emit('error', 'No avatar make trigger found.', 'user')
    } else {
      trigger.addEventListener('click', this.pickImage)
    }

    // listen for input file changes
    let fileInput = this.$refs.input
    fileInput.addEventListener('change', () => {
      if (fileInput.files != null && fileInput.files[0] != null) {
        let reader = new FileReader()
        reader.onload = e => {
          this.dataUrl = e.target.result
        }

        reader.readAsDataURL(fileInput.files[0])

        this.filename = fileInput.files[0].name || 'unknown'
        this.mimeType = this.mimeType || fileInput.files[0].type
        this.$emit('changed', fileInput.files[0], reader)
      }
    })
  },
  methods: {
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_TOKEN_MEDIA_SERVER'
    ]),

    destroy () {
      this.cropper.destroy()
      this.$refs.input.value = ''
      this.dataUrl = undefined
    },
    submit () {
      this.$emit('submit')
      if (this.uploadUrl) {
        this.uploadImage()
      } else if (this.uploadHandler) {
        this.uploadHandler(this.cropper)
      } else {
        this.$emit('error', 'No upload handler found.', 'user')
      }
      this.destroy()
    },
    pickImage (e) {
      this.$refs.input.click()
      e.preventDefault()
      e.stopPropagation()
    },
    createCropper () {
      this.cropper = new Cropper(this.$refs.img, this.cropperOptions)
    },
    async uploadImage () {
      try {
        let base64 = await this.cropper.getCroppedCanvas(this.outputOptions).toDataURL()
        let block = base64.split(';')
        let mimeType = block[0].split(':')[1]
        let realData = block[1].split(',')[1]
        let canvasBlob = this.convertB64toBlob(realData, mimeType)
        let img = new File([canvasBlob], this.filename)
        let formData = new FormData()
        formData.append('file', img)
        formData.append('directory', 'Stock/Profiles')
        formData.append('forceOverride', true)
        let currentObj = this
        let xhr = new XMLHttpRequest()
        this.$emit('uploading', null, xhr)

        await axios({
          method: 'post',
          timeout: 6000,
          url: this.mediaServer.domain + '/api/v1/upload/',
          data: formData,
          headers: { 'Authorization': `JWT ${this.mediaServer.token}` }
        })
          .then(function (res) {
            let xhr = new XMLHttpRequest()
            this.$emit('completed', res, xhr)
            this.$emit('uploaded', res, xhr)
            if ([200, 201, 204].indexOf(xhr.status) > -1) {
              this.$emit('uploaded', res, xhr)
            } else {
              this.$emit('error', 'Image upload fail.', 'upload', xhr)
            }
          }.bind(this))
          .catch(function (error) {
            currentObj.ON_SHOW_TOAST({
              message: error,
              styleType: 'danger'
            })
          })
      } catch (err) {
        this.ON_SHOW_TOAST({
          message: err,
          styleType: 'danger'
        })
        let xhr = new XMLHttpRequest()
        this.$emit('error', 'Image upload fail.' + err.message, 'upload', xhr)
      }
    },
    convertB64toBlob (b64Data, contentType, sliceSize) {
      contentType = contentType || ''
      sliceSize = sliceSize || 512
      var byteCharacters = atob(b64Data)
      var byteArrays = []
      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize)
        var byteNumbers = new Array(slice.length)
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }
        var byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }
      var blob = new Blob(byteArrays, { type: contentType })
      return blob
    }

  }
}
</script>

<style lang="scss">
.avatar-cropper {
  .avatar-cropper-overlay {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
  }

  .avatar-cropper-img-input {
    display: none;
  }

  .avatar-cropper-close {
    float: right;
    padding: 20px;
    font-size: 3rem;
    color: #fff;
    font-weight: 100;
    text-shadow: 0px 1px rgba(40, 40, 40, 0.3);
  }

  .avatar-cropper-mark {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
  }

  .avatar-cropper-container {
    background: #fff;
    z-index: 999;
    box-shadow: 1px 1px 5px rgba(100, 100, 100, 0.14);

    .avatar-cropper-image-container {
      position: relative;
      max-width: 400px;
      height: 300px;
    }
    img {
      max-width: 100%;
      height: 100%;
    }

    .avatar-cropper-footer {
      display: flex;
      align-items: stretch;
      align-content: stretch;
      justify-content: space-between;

      .avatar-cropper-btn {
        width: 50%;
        padding: 15px 0;
        cursor: pointer;
        border: none;
        background: transparent;
        outline: none;
        &:hover {
          background-color: #2aabd2;
          color: #fff;
        }
      }
    }
  }
}
</style>
