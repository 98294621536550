import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import {
  isCookieEnabled,
  setCookie,
  removeCookie
} from 'tiny-cookie'

// state
const state = {
  currentUser: null,
  login: {},
  groups: [],
  users: [],
  customerData: [],
  prepaidCards: [],
  prepaidCardsPrint: []
}

// getters
const getters = {
  GET_CURRENT_ACCOUNT: state => {
    return state.login
  },
  GROUP_DATA_LIST: state => {
    return state.groups
  },
  USER_LIST_DATA: state => {
    return state.users
  },
  CUSTOMER_LIST_DATA: state => {
    return state.customerData
  },
  PREPAID_CARD_LIST_DATA: state => {
    return state.prepaidCards
  },
  PREPAID_CARDS_PRINT_DATA: state => {
    return state.prepaidCardsPrint
  }
}

// actions
const actions = {
  SET_USER_DATA: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/auth/userinfo/',
        payload,
        function (res) {
          context.commit('SET_USER_DATA_HANDLER', res.data)
          if (isCookieEnabled()) {
            setCookie('role', res.data.response.group_id)
            resolve(res)
          } else {
            reject(res)
          }
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  LOGIN (context, payload) {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        'api/v1/auth/jwt-auth/',
        payload,
        function (res) {
          if (isCookieEnabled()) {
            setCookie('stock_token', res.data.response.token)
          }
          context.commit('LOGIN_USER_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          if (isCookieEnabled()) {
            removeCookie('stock_token')
          }
          reject(error)
        }
      )
    })
  },
  LOGOUT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        'api/v1/auth/jwt-refresh/',
        {
          token: payload
        },
        function (res) {
          context.commit('LOGOUT', res.data)
          if (isCookieEnabled()) {
            removeCookie('stock_token')
            resolve(res)
          } else {
            let error = ''
            reject(error)
          }
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_GROUP_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/sys_group/`,
        payload,
        function (res) {
          context.commit('GET_GROUP_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  REGISTER_ACCOUNT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/user/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_CURRENT_USER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `api/v1/user/update/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_USER_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/user/`,
        payload,
        function (res) {
          context.commit('GET_USER_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_USER_PROFILE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `api/v1/user/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CHANGE_PASSWORD_CURRENT_USER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/user/change/password/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_USER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `api/v1/user/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },

  ACTIVATE_ACCOUNT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/accounts/activated/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  SEND_EMAIL_FORGOT_PASSWORD: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/forgot_password/send_email_reset_password/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CONFIRM_RESET_PASSWORD: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/auth/confirm-reset-password/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_CUSTOMER_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/user/get_customer_list/`,
        payload,
        function (res) {
          context.commit('GET_CUSTOMER_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  POST_CHARGE_POINT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/user/recharge/points/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  POST_REFUND_POINT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/user/refund/points/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_PREPAID_CARD: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/prepaid_card/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_PREPAID_CARD_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/prepaid_card/`,
        payload,
        function (res) {
          context.commit('GET_PREPAID_CARD_LIST_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_PREPAID_CARD: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `api/v1/prepaid_card/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_PREPAID_CARDS_PRINT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/prepaid_card/get_prepaid_cards_print/`,
        payload,
        function (res) {
          context.commit('GET_PREPAID_CARDS_PRINT_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_STATUS_PREPAID_CARD_SELECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/prepaid_card/update_status_prepaid_card_select/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_USER_GROUP: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `api/v1/user/${payload.id}/update_user_group/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  LOGIN_USER_HANDLER: (state, payload) => {
    state.currentUser = payload
  },
  SET_USER_DATA_HANDLER: (state, payload) => {
    let userData = {
      id: '',
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      lastlogin: '',
      group_name: '',
      url_avatar: '',
      phone: '',
      gender: '',
      birth_date: ''
    }
    userData.id = payload.response && payload.response.id
    userData.username = payload.response && payload.response.username
    userData.firstname = payload.response && payload.response.firstname
    userData.lastname = payload.response && payload.response.lastname
    userData.email = payload.response && payload.response.email
    userData.lastlogin = payload.response && payload.response.last_login
    userData.group_name = payload.response && payload.response.group.name
    userData.phone = payload.response && payload.response.phone
    userData.gender = payload.response && payload.response.gender
    userData.birth_date = payload.response && payload.response.birth_date
    userData.group = payload.response && payload.response.group
    userData.url_avatar = payload.response && payload.response.url_avatar
    // Set state for getter
    state.login = userData
  },
  LOGOUT: (state, payload) => {
    state.login = {
      id: '',
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      lastlogin: '',
      group_id: '',
      group_name: '',
      url_avatar: '',
      permissions: [],
      actions: [],
      phone: '',
      gender: '',
      birth_date: '',
      roles: [],
      entity_roles: [],
      parent_entity: null
    }
  },
  GET_GROUP_LIST_HANDLER: (state, payload) => {
    state.groups = payload
  },
  GET_USER_LIST_HANDLER: (state, payload) => {
    let users = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.users = { results: users, paginate: page }
  },
  GET_CUSTOMER_LIST_HANDLER: (state, payload) => {
    let customers = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.customerData = { results: customers, paginate: page }
  },
  GET_PREPAID_CARD_LIST_HANDLER: (state, payload) => {
    let prepaidCards = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page,
      totalItem: payload.total
    }
    state.prepaidCards = { results: prepaidCards, paginate: page }
  },
  GET_PREPAID_CARDS_PRINT_HANDLER: (state, payload) => {
    state.prepaidCardsPrint = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
