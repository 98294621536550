<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    dark
    floating
    persistent
    mobile-break-point="991"
    width="260"
  >
    <v-img
      :src="$store.state.app.image"
      height="100%">
      <v-layout
        class="fill-height scroll-custom"
        tag="v-list"
        column>
        <v-list-tile
          avatar
          to="/dashboard">
          <div style="text-align: center;">
            <img
              src="../../assets/StockManager.png"
              width="50%" >
          </div>
        </v-list-tile>
        <v-divider />
        <template v-for="(link, i) in links">
          <v-list-tile
            v-if="!link.subLinks"
            :key="i"
            :to="link.to"
            :active-class="$store.state.app.color"
            avatar
            class="v-list-item"
          >
            <v-list-tile-action>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-title v-text="$t(link.text)" />
          </v-list-tile>
          <v-list-group
            v-else
            :key="link.text"
            no-action>
            <v-list-tile
              slot="activator"
              class="v-list-item">
              <v-list-tile-action>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-title>
                {{ $t(link.text) }}
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-for="sublink in link.subLinks"
              :to="sublink.to"
              :key="sublink.text"
              :active-class="$store.state.app.color"
              class="v-list-item"
              style="padding-left: 15px; padding-bottom: 5px;"
            >
              <v-list-tile-action
                style="margin: 0 !important; padding: 0 !important; margin-right: 5px;"
              >
                <v-icon size="20">{{ sublink.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-sub-title
                class="font-medium"
                v-text="$t(sublink.text)"
              />
            </v-list-tile>
          </v-list-group>
        </template>
      </v-layout>
    </v-img>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapMutations, mapState, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import RoleType from 'enum/roleType'
export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    logo: require('../../assets/logo.png'),
    links: [],
    linksDefault: [
      {
        to: '/dashboard',
        icon: 'mdi-view-dashboard',
        text: 'sideBar.dashboard'
      }
    ],
    // user list
    userMenu: {
      to: '/user-list',
      icon: 'mdi-account-group',
      text: 'sideBar.userManagement'
    },
    // customer list
    customerMenu: {
      to: '/customer-list',
      icon: 'mdi-account',
      text: 'sideBar.customerManagement'
    },
    // prepaid card
    prepaidCardMenu: {
      to: '/prepaid-card-list',
      icon: 'mdi-smart-card',
      text: 'sideBar.prepaidCardManagement'
    },
    // product
    productMenu: {
      to: '/product-list',
      icon: 'mdi-barley',
      text: 'sideBar.productManagement'
    },
    // report
    reportMenu: {
      to: '/report',
      icon: 'mdi-chart-areaspline',
      text: 'sideBar.reportManagement'
    },
    // history
    historyMenu: {
      icon: 'mdi-history',
      text: 'sideBar.historyManagement',
      subLinks: []
    },
    transactionHistory: {
      to: '/history-list',
      icon: 'mdi-bitcoin',
      text: 'sideBar.transactionHistory'
    },
    // settings
    settingMenu: {
      to: '/setting',
      icon: 'mdi-cogs',
      text: 'sideBar.settingsManagement',
      subLinks: []
    },
    settingPoints: {
      to: '/settings-point',
      icon: 'mdi-currency-usd-off',
      text: 'settings.exchangeRate'
    },
    customization: {
      to: '/customization',
      icon: 'mdi-cog',
      text: 'sideBar.generalConfiguration'
    },
    templateNotificationCategories: {
      to: '/template-notification-categories',
      icon: 'mdi-bell',
      text: 'sideBar.configTemplateNotification'
    },
    questionAndAnswer: {
      to: '/question-and-answer',
      icon: 'mdi-chat-question-outline',
      text: 'sideBar.questionAndAnswer'
    },
    feedback: {
      to: '/feedback',
      icon: 'mdi-comment-quote',
      text: 'sideBar.feedback'
    }
  }),
  computed: {
    ...mapState(['image', 'color']),
    ...mapGetters(['GET_CURRENT_ACCOUNT']),
    inputValue: {
      get () {
        return this.$store.state.app.drawer
      },
      set (val) {
        this.setDrawer(val)
      }
    },
    items () {
      return this.$t('Layout.View.items')
    }
  },
  watch: {
    GET_CURRENT_ACCOUNT: function () {
      this.settingMenu.subLinks = []
      this.links = [...this.linksDefault]
      if (this.isSuperAdmin()) {
        this.historyMenu.subLinks.push(this.transactionHistory)
        this.settingMenu.subLinks.push(
          this.settingPoints,
          this.customization,
          this.templateNotificationCategories,
          this.questionAndAnswer,
          this.feedback
        )
        this.links.push(
          this.userMenu,
          this.customerMenu,
          this.prepaidCardMenu,
          this.productMenu,
          this.reportMenu,
          this.historyMenu,
          this.settingMenu
        )
      } else if (this.isAdmin()) {
        this.historyMenu.subLinks.push(this.transactionHistory)
        this.settingMenu.subLinks.push(
          this.settingPoints,
          this.customization,
          this.templateNotificationCategories,
          this.questionAndAnswer,
          this.feedback
        )
        this.links.push(
          this.userMenu,
          this.customerMenu,
          this.prepaidCardMenu,
          this.productMenu,
          this.reportMenu,
          this.historyMenu,
          this.settingMenu
        )
      } else if (this.isAccountant()) {
        this.historyMenu.subLinks.push(this.transactionHistory)
        this.settingMenu.subLinks.push(
          this.settingPoints,
          this.customization,
          this.templateNotificationCategories,
          this.questionAndAnswer,
          this.feedback
        )
        this.links.push(
          this.userMenu,
          this.customerMenu,
          this.prepaidCardMenu,
          this.productMenu,
          this.reportMenu,
          this.historyMenu,
          this.settingMenu
        )
      } else if (this.isModerator()) {
        this.historyMenu.subLinks.push(this.transactionHistory)
        this.settingMenu.subLinks.push(
          this.settingPoints,
          this.customization,
          this.templateNotificationCategories,
          this.questionAndAnswer,
          this.feedback
        )
        this.links.push(
          this.userMenu,
          this.customerMenu,
          // this.prepaidCardMenu,
          this.productMenu,
          this.reportMenu,
          this.historyMenu,
          this.settingMenu
        )
      }
    }
  },
  created () {
    this.links = [...this.linksDefault]
  },
  methods: {
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.hasSystemRole(
        RoleType.ADMIN,
        this.GET_CURRENT_ACCOUNT
      )
    },
    isModerator: function () {
      return functionUtils.hasSystemRole(
        RoleType.MODERATOR,
        this.GET_CURRENT_ACCOUNT
      )
    },
    // Check Accountant
    isAccountant: function () {
      return functionUtils.hasSystemRole(
        RoleType.ACCOUNTANT,
        this.GET_CURRENT_ACCOUNT
      )
    },
    ...mapMutations(['setDrawer', 'toggleDrawer'])
  }
}
</script>
<style lang="scss" scoped>
.v-navigation-drawer .v-list .v-list-item .v-list__tile__action i {
  color: #ffffff !important;
}
.v-navigation-drawer .v-list .v-list-item > .v-list__tile .v-list__tile__title {
  color: #ffffff !important;
}
.theme--dark.v-list .v-list__tile__sub-title {
  white-space: normal;
}
</style>
