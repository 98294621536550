import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'

// state
const state = {
  transactionHistoryList: []
}

// getters
const getters = {
  GET_TRANSACTION_HISTORY_LIST_DATA: state => {
    return state.transactionHistoryList
  }
}

// actions
const actions = {
  GET_TRANSACTION_HISTORY_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/history/transaction/`,
        payload,
        function (res) {
          context.commit('GET_TRANSACTION_HISTORY_LIST', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_TRANSACTION_HISTORY_LIST: (state, payload) => {
    let transactionHistory = payload.results !== undefined ? payload.results : payload
    if (payload.results !== undefined) {
      let page = {
        totalPage: payload.num_pages,
        currentPage: payload.page,
        pageSize: payload.page_size,
        perPage: payload.per_page
      }
      state.transactionHistoryList = { results: transactionHistory, paginate: page }
    } else {
      state.transactionHistoryList = payload
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
