import { languages } from './data'
import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'

const state = {
  languages,
  selectedLocale: languages[1],
  objectSettings: {},
  supplierAccessCode: [],
  templateEmailCategories: [],
  templateEmailList: []
}

// getters
const getters = {
  languages: state => {
    return state.languages
  },
  selectedLocale: state => {
    return state.selectedLocale
  }
}

// actions
const actions = {
  changeLanguage (context, payload) {
    context.commit('changeLanguageHandler', payload)
  },
  SAVE_EXCHANGE_RATE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/settings/point/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_EXCHANGE_RATE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/settings/point/get/first/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_SYSTEM_NUMBER_ITEM_OF_PAGE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/number_item_of_page/get_system_number_item_of_page/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_SYSTEM_NUMBER_ITEM_OF_PAGE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/number_item_of_page/update_system_number_item_of_page/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_USER_NUMBER_ITEM_OF_PAGE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/number_item_of_page/get_user_number_item_of_page/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_USER_NUMBER_ITEM_OF_PAGE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/number_item_of_page/update_user_number_item_of_page/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  /**
    * method for setting language state
   */
  changeLanguageHandler (state, language) {
    state.selectedLocale = language
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
