<template>
  <v-footer
    id="core-footer"
    absolute
    height="82"
  >
    <div class="footer-items">
      <span
        v-for="link in links"
        :key="link.name"
      >
        <a
          :href="link.Link"
          class="tertiary--text footer-links">{{ link.name }}</a>
      </span>
    </div>
    <v-spacer/>
    <span class="font-weight-light copyright">
      <a
        href="https://www.avatarnext.com/evoucher"
        target="_blank">
        &copy;
        {{ (new Date()).getFullYear() }}
        {{ 'Avatar Solutions' }}
      </a>
    </span>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    links: [
    ]
  })
}
</script>

<style>
#core-footer {
  z-index: 0;
}
.text-footer-class{
  color: #66bb6a !important;
}
</style>
