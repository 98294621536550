import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'

// state
const state = {
  reportPageData: []
}

// getters
const getters = {
  GET_DATA_FOR_PAGE_REPORT_DATA: (state, payload) => {
    return state.reportPageData
  }
}

const actions = {
  GET_DATA_FOR_PAGE_REPORT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/report/get_data_page_report/',
        payload,
        function (res) {
          context.commit('GET_DATA_FOR_PAGE_REPORT', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_DATA_FOR_PAGE_REPORT: (state, payload) => {
    state.reportPageData = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
