<template>
  <div class="card vue-avatar-cropper-demo text-center">
    <div class="card-body">
      <img
        id="pick-avatar"
        :src="user.avatar"
        title="Chọn Hình Ảnh"
        class="card-img avatar">
    </div>
    <avatar-cropper
      trigger="#pick-avatar"
      upload-url="upload_url"
      @uploading="handleUploading"
      @uploaded="handleUploaded"
      @completed="handleCompleted"
      @error="handlerError"
    />
  </div>
</template>
<script>
import Avatar from 'vue-avatar'
import avatarCropper from '@/components/AvatarCropper'
import functionUtils from 'utils/functionUtils'

export default {
  components: {
    Avatar,
    avatarCropper
  },
  props: {
    urlAvatar: {
      type: String,
      default: () => {
        return null
      }
    }
  },
  data: () => ({
    message: '',
    user: {
      avatar: ''
    },
    url_avatar_default: require('../assets/avatar-default.png')
  }),
  watch: {
    'urlAvatar': function () {
      this.user.avatar = !functionUtils.validateStringIsNull(this.urlAvatar) ? this.urlAvatar : this.url_avatar_default
    }
  },
  created () {
    this.user.avatar = !functionUtils.validateStringIsNull(this.urlAvatar) ? this.urlAvatar : this.url_avatar_default
  },
  methods: {
    handleUploading () {
      this.message = 'uploading...'
    },
    handleUploaded (response) {
      if (parseInt(response.status) === 201) {
        this.user.avatar = response.data.domain + response.data.url

        // Maybe you need call vuex action to
        // update user avatar, for example:
        this.message = 'user avatar updated.'
        this.$emit('completed', this.user.avatar)
      }
    },
    handleCompleted (response, form, xhr) {
      this.message = 'upload completed.'
    },
    handlerError (message, type, xhr) {
      this.message = 'Oops! Something went wrong...'
    }
  }
}
</script>
<style scoped>
input[type="file"] {
  display: none;
}
.vue-avatar-cropper-demo {
  max-width: 18em;
  margin: 0 auto;
}
.avatar {
  width: 160px;
  border-radius: 6px;
  display: block;
  margin: auto;
}
.card-img-overlay {
  display: none;
  transition: all 0.5s;
}
.card:hover .card-img-overlay {
  display: block;
}
</style>
