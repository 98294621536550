import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'

// state
const state = {
  productList: [],
  productDetail: [],
  productWithQrCode: [],
  productPrint: []
}

// getters
const getters = {
  GET_PRODUCT_LIST_DATA: state => {
    return state.productList
  },
  GET_PRODUCT_DETAIL_DATA: state => {
    return state.productDetail
  },
  GET_PRODUCT_WITH_QR_CODE_DATA: state => {
    return state.productWithQrCode
  },
  GET_PRODUCT_PRINT_DATA: state => {
    return state.productPrint
  }
}

// actions
const actions = {
  GET_PRODUCT_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/product/`,
        payload,
        function (res) {
          context.commit('GET_PRODUCT_LIST', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_PRODUCT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/product/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_PRODUCT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `api/v1/product/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_PRODUCT_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/product/${payload.id}/`,
        payload,
        function (res) {
          context.commit('GET_PRODUCT_DETAIL', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_PRODUCT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `api/v1/product/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_PRODUCT_WITH_QR_CODE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/without_login/get_product_with_qr_code/`,
        payload,
        function (res) {
          context.commit('GET_PRODUCT_WITH_QR_CODE', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_PRIORITY_PRODUCT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/product/update_priority_product/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_PRODUCT_PRINT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/product/get_prepaid_cards_print/`,
        payload,
        function (res) {
          context.commit('GET_PRODUCT_PRINT_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_PRODUCT_LIST: (state, payload) => {
    let product = payload.results !== undefined ? payload.results : payload
    if (payload.results !== undefined) {
      let page = {
        totalPage: payload.num_pages,
        currentPage: payload.page,
        pageSize: payload.page_size,
        perPage: payload.per_page
      }
      state.productList = { results: product, paginate: page }
    } else {
      state.productList = payload
    }
  },
  GET_PRODUCT_DETAIL: (state, payload) => {
    state.productDetail = payload
  },
  GET_PRODUCT_WITH_QR_CODE: (state, payload) => {
    state.productWithQrCode = payload
  },
  GET_PRODUCT_PRINT_HANDLER: (state, payload) => {
    state.productPrint = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
