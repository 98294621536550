// =========================================================
// * Vuetify Material Dashboard - v1.0.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licenses under MIT

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

// Components
import './components'
import './lib/css'
import './lib/script'
import './lib/global'
// Plugins
import './plugins'
// import { sync } from 'vuex-router-sync'
import VueI18n from 'vue-i18n'
// Application imports
import App from './App'
import router from '@/router'
import store from '@/store'
import messages from './lang'
import GlobalComponents from './gloabalComponents'
import BootstrapVue from 'bootstrap-vue'
import 'chartist/dist/chartist.min.css'
import 'chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.min'
import VueClipboard from 'vue-clipboard2'
import CKEditor from '@ckeditor/ckeditor5-vue2'
Vue.use(CKEditor)
Vue.use(VueClipboard)
Vue.use(require('vue-chartist'))
// Sync store with router
Vue.use(VueI18n)
Vue.use(BootstrapVue)
// creating a instance of vue localisation module
const i18n = new VueI18n({
  locale: store.getters.selectedLocale.locale,
  messages
})
Vue.config.productionTip = false
// Global components
Vue.use(GlobalComponents)
/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
