import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'

// state
const state = {
  templateNotificationCategories: [],
  templateNotificationList: []
}

// getters
const getters = {
  TEMPLATE_NOTIFICATION_CATEGORIES_DATA: state => {
    return state.templateNotificationCategories
  },
  TEMPLATE_NOTIFICATION_DATA: state => {
    return state.templateNotificationList
  }
}

const actions = {
  PUSH_NOTIFICATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        'api/v1/pushnotification/',
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_TEMPLATE_NOTIFICATION_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/template_notification/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_TEMPLATE_NOTIFICATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/template_notification/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_TEMPLATE_NOTIFICATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/template_notification/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_TEMPLATE_NOTIFICATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/template_notification/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_TEMPLATE_NOTIFICATION_CATEGORIES: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/template_notification/get_template_notification_categories/`,
        payload,
        function (res) {
          context.commit('GET_TEMPLATE_NOTIFICATION_CATEGORIES_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_FIELDS_TEMPLATE_NOTIFICATION_BY_CATEGORY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/template_notification/get_fields_template_notification_by_category/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_TEMPLATE_NOTIFICATION_DEFAULT_BY_CATEGORY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/template_notification/get_template_notification_default_by_category/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_TEMPLATE_NOTIFICATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/template_notification/get_template_notification_by_category/`,
        payload,
        function (res) {
          context.commit('GET_TEMPLATE_NOTIFICATION_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_TEMPLATE_NOTIFICATION_CATEGORIES_HANDLER (state, payload) {
    let categories = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages && payload.num_pages,
      currentPage: payload.page && payload.page,
      pageSize: payload.page_size && payload.page_size,
      perPage: payload.per_page && payload.per_page
    }
    state.templateNotificationCategories = { results: categories, paginate: page }
  },
  GET_TEMPLATE_NOTIFICATION_HANDLER (state, payload) {
    let templateNotificationList = payload.results ? payload.results : payload
    let page = {
      totalPage: payload.num_pages && payload.num_pages,
      currentPage: payload.page && payload.page,
      pageSize: payload.page_size && payload.page_size,
      perPage: payload.per_page && payload.per_page
    }
    state.templateNotificationList = { results: templateNotificationList, paginate: page }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
