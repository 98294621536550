/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'

// Store functionality
// import modules from './modules'
// Functions
import settings from './modules/settings'
import users from './modules/users'
import toast from './modules/toast'
import app from './modules/app'
import account from './modules/account'
import product from './modules/product'
import history from './modules/history'
import smtp from './modules/smtp'
import media from './modules/media'
import notification from './modules/notification'
import dashboard from './modules/dashboard'
import question from './modules/question'
import report from './modules/report'
import feedback from './modules/feedback'
Vue.use(Vuex)

// Create a new store
const store = new Vuex.Store({
  modules: {
    settings,
    users,
    toast,
    app,
    account,
    product,
    history,
    smtp,
    media,
    notification,
    dashboard,
    question,
    report,
    feedback
  }
})

export default store
