export default {
  'common': {
    'logOut': 'Đăng xuất',
    'networkError': 'Lỗi Kết Nối Mạng',
    'save': 'Lưu',
    'close': 'Đóng',
    'success': 'Thành Công',
    'nonumber': 'STT',
    'search': 'Tìm kiếm',
    'role': 'Vai trò',
    'state': 'Trạng thái',
    'actions': 'Công Cụ',
    'noDataAvailable': 'Không có dữ liệu',
    'add': 'Thêm Mới',
    'email': 'Email',
    'requiredFields': 'Thông tin bắt buộc!',
    'confirm': 'Xác Nhận',
    'delete': 'Xóa',
    'resetPassword': 'Lấy lại mật khẩu',
    'notification': 'Thông báo !',
    'somethingWentWrong': 'Có Lỗi Xảy Ra !',
    'copyUrl': 'Sao chép đường dẫn',
    'connect': 'Kết nối',
    'callBackLogin': 'Trở lại trang đăng nhập',
    'cannotExceed10MB': 'File không được vượt quá 10mb',
    'update': 'Cập nhật',
    'insert': 'Chèn',
    'code': 'Mã',
    'name': 'Tên',
    'point': 'Giá trị',
    'print': 'In',
    'logo': 'Logo',
    'month': 'Tháng',
    'date': 'Ngày',
    'history': 'Lịch sử',
    'vnd': 'VNĐ'
  },
  'sideBar': {
    'dashboard': 'Dashboard',
    'userManagement': 'Quản lý tài khoản',
    'productManagement': 'Quản lý sản phẩm',
    'settingsManagement': 'Cấu hình',
    'customerManagement': 'Quản lý khách hàng',
    'historyManagement': 'Lịch sử',
    'transactionHistory': 'Lịch sử giao dịch',
    'generalConfiguration': 'Cấu hình chung',
    'configTemplateNotification': 'Cấu hình mẫu thông báo',
    'questionAndAnswer': 'Hỏi đáp',
    'prepaidCardManagement': 'Quản lý thẻ trả trước',
    'reportManagement': 'Thống kê',
    'feedback': 'Phản hồi'
  },
  'notification': {
    'new': 'Mới',
    'customer': {
      'rechargedPointsTitle': 'Tích điểm thành công',
      'rechargedPoints': 'Bạn vừa tích {0} điểm vào tài khoản. Tổng số điểm của bạn là {1}'
    }
  },
  'localize': {
    'vietnamese': 'Tiếng Việt',
    'english': 'Tiếng Anh'
  },
  'userProfile': {
    'userProfile': 'Thông tin tài khoản',
    'genderType': {
      'other': 'Khác',
      'male': 'Nam',
      'female': 'Nữ'
    },
    'gender': 'Giới tính',
    'birthday': 'Ngày sinh',
    'phone': 'Điện thoại',
    'address': 'Địa chỉ',
    'firstName': 'Tên',
    'lastName': 'Họ',
    'userName': 'Tài khoản',
    'email': 'Địa chỉ email',
    'aboutMe': 'Về tôi',
    'editProfile': 'Chỉnh sửa hồ sơ',
    'completeYourProfile': 'Hoàn thành hồ sơ của bạn',
    'updateProfile': 'Cập Nhật hồ sơ',
    'identificationNumber': 'Chứng minh thư',
    'passport': 'Hộ chiếu'
  },
  'login': {
    'somethingWentWrong': 'Có Lỗi Xảy Ra !',
    'loginInvalid': 'Tài Khoản Hoặc Mật Khẩu Không Đúng',
    'login': 'Đăng Nhập',
    'username': 'Tên tài khoản',
    'password': 'Mật khẩu',
    'customerCannotLogin': 'Khách hàng thì không thể đăng nhập',
    'inCorrectPassword': 'Mật khẩu không đúng ',
    'youHaveNotRegister': 'Bạn chưa đăng ký, vui lòng đăng ký!',
    'userIsDeleted': 'Tài khoản đã bị xóa',
    'accountIsNotActive': 'Tài khoản chưa được kích hoạt',
    'pleaseActivateAccount': 'Vui lòng kích hoạt tài khoản trong email để đăng nhập vào hệ thống',
    'Userisnotactiveordeleted': 'Người dùng không hoạt động hoặc đã bị xóa',
    'forgotPassword': 'Quên mật khẩu?',
    'entityInActive': 'Tài khoản quản lý nhà cung cấp của bạn chưa được kích hoạt!'
  },
  'user': {
    'username': 'Tên Đăng Nhập',
    'fullname': 'Họ và tên',
    'firstname': 'Tên',
    'lastname': 'Họ',
    'email': 'Email',
    'lastlogin': 'Lần Đăng Nhập Cuối',
    'age': 'Tuổi',
    'dob': 'Ngày sinh',
    'phone': 'Điện thoại',
    'gender': 'Giới tính',
    'addnew': 'Thêm Mới',
    'add': 'Thêm',
    'password': 'Mật Khẩu',
    'uppercase': 'Chữ Hoa',
    'lowercase': 'Chữ Thường',
    'digit': 'Số',
    'group': 'Nhóm',
    'title': 'Danh Sách Nhân Viên',
    'title_organizer': 'Danh Sách Nhà Tổ Chức',
    'privilege': 'Phân quyền',
    'country': 'Quốc Gia',
    'is_active': 'Kích Hoạt',
    'activate_account': 'Kích Hoạt Tài Khoản',
    're_send_email': 'Gửi lại thông tin kích hoạt',
    'joined_date': 'Tham Gia',
    'device': 'Số Thiết Bị',
    'device_list': 'Danh Sách Thiết Bị Kết Nối',
    'app_type': 'Loại Thiết Bị',
    'app_type_data': {
      'ios': 'iOS',
      'android': 'Android',
      'other': 'Other'
    },
    'last_access': 'Lần Truy Cập Cuối',
    'last_login': 'Lần Đăng Nhập Cuối',
    'status': 'Trạng Thái',
    'status_data': {
      'active': 'Kích Hoạt',
      'inactive': 'Không Kích Hoạt'
    },
    'info': 'Thông Tin',
    'group_mode': {
      'Admin': 'Quản Trị',
      'Moderator': 'Điều Hành',
      'User': 'Người Dùng',
      'Accountant': 'Kế toán'
    },
    'profile': 'Thông Tin Nhân Viên',
    'gender_type': ['Nam', 'Nữ', 'Khác'],
    'confirm_pass': {
      'title': 'Xác Nhận Thay Đổi Mật Khẩu',
      'content': 'Bạn có chắc chắn muốn thay đổi mật khẩu?',
      'btnClose': 'Đóng',
      'btnConfirm': 'Xác Nhận'
    },
    'addNewUser': 'Thêm mới người dùng',
    'userInfoTitle': 'Thông tin người dùng',
    'confirmDeleteUserTitle': 'Bạn có chắc chắn muốn xoá người dùng này?',
    'successfulActivation': 'Kích hoạt thành công',
    'failedActivation': 'Kích hoạt thất bại, vui lòng thử lại sau',
    'redirectLoginPage': 'Quay về trang đăng nhập sau 5 giây nữa',
    'activeThisAccount': 'Kích hoạt tài khoản này',
    'address': 'Địa chỉ',
    'resetPassword': {
      'linkNoLongerValid': 'Đường dẫn xác nhận không còn tồn tại.'
    },
    'changePassword': 'Chỉnh sửa mật khẩu',
    'ChangePasswordModal': {
      'title': 'Chỉnh sửa mật khẩu',
      'guide': 'Sử dụng mẫu này để thay đổi mật khẩu của bạn. Mật khẩu mới sẽ được áp dụng cho lần đăng nhập tiếp theo',
      'confirmNewPass': 'Nhập lại mật khẩu mới',
      'enterOldPass': 'Nhập mật khẩu cũ',
      'enterNewPass': 'Nhập mật khẩu mới',
      'enterNewPassConfirm': 'Xác nhận mật khẩu mới',
      'upperCase': 'Chữ hoa',
      'lowerCase': 'Chữ thường',
      'digit': 'Số',
      'compareNewPassAndConfirmPassError': 'Mật khẩu mới và mật khẩu xác nhận không trùng nhau',
      'weakPassError': 'Mật khẩu bạn nhập chưa đạt yêu cầu, hãy thử lại',
      'oldPassIncorrect': 'Mật khẩu cũ bạn nhập không chính xác, hãy thử lại'
    }
  },
  'users': {
    'add': 'Thêm Người Dùng',
    'userList': 'Danh sách người dùng',
    'fullName': 'Họ và tên',
    'phone': 'Số điện thoại',
    'gender': 'Giới tính',
    'username': 'Tên người dùng',
    'points': 'Điểm',
    'userDetail': 'Chi tiết người dùng',
    'assignedRoles': 'Vai trò được giao',
    'availableRoles': 'Vai trò khả dụng',
    'email': 'Email',
    'identification': 'Định danh',
    'dateCreated': 'Ngày tạo',
    'extraInfo': 'Thông tin bổ sung',
    'errorText': {
      'indentAlreadyExist': 'Định danh này đã tồn tại',
      'emailAlreadyExist': 'Email đã tồn tại',
      'phoneAlreadyExist': 'Điện Thoại đã tồn tại'
    },
    'viewDetail': 'Xem chi tiết',
    'addPoints': 'Tích điểm',
    'refundPoints': 'Hoàn điểm',
    'depositAmount': 'Số tiền nạp',
    'chargePointsModalContent': 'Nạp điểm cho người dùng',
    'numOfExchangePoint': 'Số điểm quy đổi được là',
    'valueMustBeGreaterThanZero': 'Giá trị phải lớn hơn 0',
    'confirmChargePointsTitle': 'Bạn có chắc muốn nạp điểm cho {0}?',
    'state': {
      'active': 'Đã kích hoạt',
      'in_active': 'Chưa kích hoạt'
    },
    'status': {
      'lock': 'Khóa'
    },
    'blockedAccount': 'Tài khoản của bạn đã bị khóa',
    'blockedCard': 'Thẻ của bạn đã bị khóa',
    'customerRefundPoint': {
      'customerInfo': 'Thông tin khách hàng',
      'customerCurrentPoint': 'số điểm hiện tại là',
      'confirmCustomerRefundPointTitle': 'Bạn có chắc chắn muốn hoàn điểm cho khách hàng {0}?'
    },
    'prepaidCardRefundPoint': {
      'prepaidCardInfo': 'Thông tin thẻ trả trước',
      'prepaidCardCurrentPoint': 'số điểm hiện tại là',
      'confirmPrepaidCardRefundPointTitle': 'Bạn có chắc chắn muốn hoàn điểm cho thẻ {0}?'
    },
    'exchangedMoney': 'Số tiền quy đổi',
    'lockCustomerTitle': 'Bạn có chắc chắn muốn khóa khách hàng này?',
    'unlockCustomerTitle': 'Bạn có chắc chắn muốn mở khóa cho khách hàng này?',
    'moveCustomerToStaff': {
      'title': 'Chuyển thành nhân viên',
      'notiUserHaveNotRefundedPoints': 'Khách hàng chưa hoàn điểm. Vui lòng hoàn điểm trước khi chuyển khách hàng thành nhân viên',
      'notiConfirm': 'Bạn có chắc chắn muốn chuyển {0} thành nhân viên?'
    }
  },
  'decentralizationGroup': {
    'decentralizationGroupList': 'Danh sách nhóm chức năng',
    'addNewDecentralizationGroup': 'Thêm mới nhóm chức năng',
    'updateDecentralizationGroup': 'Cập nhật nhóm chức năng',
    'functionalList': 'Danh sách chức năng',
    'currentFunctionGroup': 'Nhóm chức năng hiện tại',
    'functionGroupNotYet': 'Nhóm chức năng chưa có',
    'privilege': 'Phân quyền',
    'availableGroups': 'Nhóm khả dụng',
    'assignedGroups': 'Nhóm được gán',
    'onShowModalDeletedecentralizationGroupTitle': 'Bạn có chắc muốn xóa nhóm chức năng này không?'
  },
  'register': {
    'alreadyAccount': 'Bạn đã có tài khoản?',
    'createNewOne': 'Hãy tạo tài khoản mới.',
    'notHaveAccount': 'Bạn chưa có tài khoản?',
    'accountAlreadyExist': 'Tài khoản hoặc email đã tồn tại',
    'notSpecialCharacterUsername': 'Tên tài khoản không chứa kí tự đặc biệt',
    'nameMust8CharacterOrMore': 'Tên tài khoản phải từ 8 kí tự trở lên',
    'emailInvalidate': 'Email không đúng định dạng',
    'passwordIncorrect': 'Mật khẩu không khớp',
    'confirmPassword': 'Xác nhận mật khẩu',
    'phoneInvalidate': 'Số điện thoại không đúng định dạng'
  },
  'product': {
    'title': 'Danh sách sản phẩm',
    'name': 'Tên sản phẩm',
    'productImg': 'Ảnh sản phẩm',
    'productPoint': 'Trị giá',
    'description': 'Mô tả',
    'addNewProduct': 'Thêm mới sản phẩm',
    'productDetail': 'Chi tiết sản phẩm',
    'confirmDeleteProductTitle': 'Bạn có chắc chắn muốn xoá sản phẩm này?',
    'productImgNote': 'Nhấn vào ảnh để thêm hoặc cập nhật',
    'removeProductImg': 'Xóa ảnh sản phẩm',
    'productInfo': 'Thông tin sản phẩm',
    'code': 'Mã sản phẩm',
    'numberOfItemPerRow': 'Số lượng sản phẩm trên hàng',
    'quantity': 'Số lượng'
  },
  'settings': {
    'exchangeRate': 'Tỷ giá',
    'numberItemOfPage': {
      'title': 'Số lượng hàng trong một trang',
      'systemNumberItemOfPage': 'Hệ thống',
      'userNumberItemOfPage': 'Người dùng',
      'value': 'Giá trị'
    }
  },
  'history': {
    'dateAction': 'Thời gian',
    'personAction': 'Người thực hiện',
    'description': 'Mô tả',
    'customerName': 'Tên khách hàng',
    'customerEmail': 'Email khách hàng',
    'action': 'Hành động',
    'transactionHistory': {
      'title': 'Lịch sử giao dịch',
      'transactionType': {
        'addPoints': 'Tích điểm',
        'userPoints': 'Sử dụng điểm',
        'refundPoints': 'Hoàn điểm'
      },
      'customer': 'Khách hàng'
    }
  },
  'forgotPassword': {
    'send': 'Gửi',
    'email': 'Email',
    'newPassword': 'Mật khẩu mới',
    'confirmPassword': 'Xác nhận mật khẩu',
    'pleaseCheckEmailToChangePassword': 'Vui lòng kiểm tra email để lấy lại mật khẩu !',
    'confirmResetPassword': 'Bạn có chắc muốn lấy lại mật khẩu cho người dùng này?',
    'emailIsNotRegistered': 'Email được chưa đăng ký'
  },
  'generalConfiguration': {
    'serverSMTP': 'Máy chủ SMTP',
    'smtpDomain': 'Tên miền',
    'smtpUsername': 'Tên đăng nhập',
    'smtpPassword': 'Mật khẩu',
    'smtpConfigurationInfo': 'Thông tin cấu hình SMTP',
    'name': 'Tên',
    'alias': 'Bí danh',
    'description': 'Mô tả',
    'pageSize': 'Kích thước trang',
    'subContent': 'Nội dung thêm',
    'emailConfiguration': 'Cấu hình email',
    'port': 'Cổng',
    'emailAccount': 'Tài khoản email',
    'useTLS': 'Sử dụng TLS',
    'discount': 'Chiết khấu',
    'activeSupplierCode': 'Mã kíck hoạt nhà cung cấp',
    'emailPassword': 'Mật khẩu',
    'serverMedia': 'Máy Chủ Lưu Trữ',
    'mediaDomain': 'Tên miền',
    'mediaUsername': 'Tên đăng nhập',
    'mediaPassword': 'Mật khẩu',
    'mediaProject': 'Thông Tin Cấu Hình Máy Chủ Lưu Trữ',
    'mediaProjectName': 'Tên',
    'mediaPageSize': 'Kích thước trang',
    'mediaDescription': 'Mô tả'
  },
  'customization': {
    'configOutgoingEmail': 'Cấu hình email gửi đi',
    'projectDefault': 'Dự án mặc định',
    'errorMessage': {
      'projectIdIsNone': 'Vui lòng chọn dự án cần kết nối',
      'notFoundProject': 'Không tìm thấy dự án của bạn',
      'infoConnectIncorrect': 'Thông tin kết nối không chính xác',
      'cannotConnectToServer': 'Không thể kết nối tới máy chủ'
    },
    'usedEmailOfSystem': 'Sử dụng email của hệ thống'
  },
  'templateNotification': {
    'title': 'Mẫu thông báo',
    'category': {
      'title': 'Danh mục mẫu thông báo',
      'list': 'Danh sách danh mục mẫu thông báo',
      'table': {
        'headerName': 'Tên danh mục',
        'headerNumberOfTemplate': 'Số lượng mẫu'
      },
      'viewDetail': 'Xem chi tiết'
    },
    'usedThisTemplate': 'Sử dụng mẫu template này',
    'name': 'Tên mẫu',
    'templateTitle': 'Tiêu đề',
    'templateContent': 'Nội dung',
    'usedDefault': 'Sử dụng mặc định',
    'list': 'Danh sách mẫu thông báo',
    'createNew': 'Tạo mẫu mới',
    'createTemplateEmail': 'Tạo mẫu thông báo',
    'updateTemplateEmail': 'Cập nhật mẫu thông báo',
    'info': 'Thông tin mẫu thông báo',
    'chooseValue': 'Chọn giá trị',
    'copy': 'Sao chép',
    'previewTemplate': 'Xem trước mẫu này',
    'chooseTemplateDefault': 'Chọn mẫu có sẵn',
    'modePreview': 'Chế độ xem trước',
    'chooseThisTemplate': 'Sử dụng mẫu này',
    'confirmDeleteTemplate': 'Bạn có chắc chắn muốn xoá mẫu thông báo này?',
    'state': {
      'active': 'Đã kích hoạt',
      'in_active': 'Chưa kích hoạt'
    }
  },
  'dashboard': {
    'byDate': 'Theo ngày',
    'byMonth': 'Theo tháng',
    'chargeAndUsePointsReport': {
      'title': 'Thống kê nạp và sử dụng điểm',
      'labels': {
        'chargePoints': 'Nạp điểm',
        'usePoints': 'Sử dụng điểm'
      }
    },
    'chargePointsReport': {
      'title': 'Thống kê tích điểm',
      'labels': {
        'customerCharge': 'Tích điểm trên app',
        'userghostCharge': 'Tích điểm trên thẻ'
      },
      'totalChargePoints': 'Tổng số điểm đã tích'
    },
    'usePointsReport': {
      'title': 'Thống kê sử dụng điểm',
      'labels': {
        'customerUsePoints': 'Sử dụng điểm trên app',
        'ghostuserUsePoints': 'Sử dụng điểm trên thẻ'
      },
      'totalUsedPoints': 'Tổng số điểm đã sử dụng'
    },
    'accountCreatedInYearReport': {
      'title': 'Thống kê số lượng tạo tài khoản, kích hoạt thẻ',
      'numberOfAccount': 'Số lượng',
      'labels': {
        'createCustomer': 'Tạo tài khoản trên app',
        'activePrepaidCard': 'Kích hoạt thẻ'
      },
      'accountAndCardActive': 'Tổng số tài khoản và thẻ kích hoạt'
    },
    'totalProductUsedReport': {
      'title': 'Thống kê số lượng sản phẩm đã sử dụng',
      'labels': {
        'customer': 'Sử dụng trên app',
        'prepaidCard': 'Sử dụng trên thẻ'
      },
      'totalProductUsed': 'Tổng số sản phẩm đã sử dụng'
    },
    'newCustomerReport': {
      'title': 'Thống kê khách hàng mới của ngày hôm nay'
    },
    'rechargeAndUsePoints': {
      'title': 'Thống kê tích và sử dụng điểm của ngày hôm nay'
    },
    'refundPointsReport': {
      'title': 'Thống kê hoàn trả điểm của ngày hôm nay'
    }
  },
  'questionAndAnswer': {
    'title': 'Danh sách hỏi đáp',
    'addQuestion': 'Thêm hỏi đáp',
    'detailQuestion': 'Chi tiết hỏi đáp',
    'questionContent': 'Nội dung câu hỏi',
    'questionAnswer': 'Câu trả lời',
    'confirmDeleteQuestion': 'Bạn có chắc chắn muốn xoá câu hỏi đáp này?',
    'orderPriority': {
      'moveToUp': 'Di chuyển lên',
      'moveToDown': 'Di chuyển xuống',
      'moveToFirst': 'Di chuyển lên đầu',
      'moveToLast': 'Di chuyển xuống cuối'
    }
  },
  'prepaidCard': {
    'title': 'Thẻ trả trước',
    'name': 'Tên thẻ',
    'code': 'Mã thẻ',
    'list': 'Danh sách thẻ trả trước',
    'add': 'Thêm mới thẻ trả trước',
    'rechargePointForCardCode': 'Nạp điểm cho mã thẻ',
    'lockCard': 'Khóa thẻ',
    'unlockCard': 'Mở khóa',
    'lockCardTitle': 'Bạn có chắc chắn muốn khóa thẻ này?',
    'unlockCardTitle': 'Bạn có chắc chắn muốn mở khóa cho thẻ này?',
    'additionalInfo': 'Thông tin thêm của thẻ',
    'mainTitle': 'Tiêu đề chính',
    'subTitle': 'Tiêu đề phụ',
    'hotline': 'Đường dây nóng',
    'printCard': 'In thẻ',
    'printCardSelected': 'In thẻ đã chọn',
    'printAllPrepaidCard': 'In tất cả thẻ',
    'note': 'Vui lòng bảo quản thẻ, điền tên và bảo mật thông tin',
    'support': 'Hỗ trợ',
    'username': 'Tên người sử dụng',
    'points': 'Số điểm',
    'rechargePointsInBulk': 'Tích điểm hàng loạt',
    'plsChoosePrepaidCardNeedRecharge': 'Vui lòng chọn thẻ cần tích điểm',
    'rechargePointsFor': 'Nạp điểm cho',
    'prepaidCard': 'thẻ',
    'status': {
      'activated': 'Đã kích hoạt',
      'inactive': 'Chưa kích hoạt'
    },
    'errorPrepaidCardInActive': 'Thẻ trả trước chưa được kích hoạt',
    'rangeQueryTitle': 'Số thẻ mỗi lần in',
    'lockAndUnlockTitle': 'Khóa/Mở khóa',
    'lockCardSelect': 'Khóa thẻ đã chọn',
    'unlockCardSelect': 'Mở khóa thẻ đã chọn',
    'selectPrepaidCardLockOrUnlock': 'Vui lòng chọn thẻ',
    'lockCardSelectTitle': 'Bạn có chắc chắn muốn khóa thẻ đã chọn?',
    'unlockCardSelectTitle': 'Bạn có chắc chắn muốn mở khóa thẻ đã chọn?'
  },
  'report': {
    'time': 'Thời gian',
    'timeList': {
      'yesterday': 'Hôm qua',
      'today': 'Hôm nay',
      '7daysAgo': '7 ngày trước',
      '30daysAgo': '30 ngày trước',
      'selectMonth': 'Tùy chọn tháng',
      'selectDate': 'Tùy chọn ngày'
    },
    'labelTextList': {
      'numberOfUserActive': 'Số khách hàng kích hoạt mới',
      'numberOfRechargePoints': 'Số điểm đã nạp',
      'numberOfPointsUsed': 'Số điểm đã sử dụng',
      'numberOfRefundPoints': 'Số điểm đã hoàn trả',
      'numberOfUsersReturn': 'Số khách hàng hoàn trả điểm',
      'numberOfPrepaidCardActive': 'Số thẻ trả trước kích hoạt'
    }
  },
  'feedback': {
    'feedbackTemplate': {
      'title': 'Mẫu phản hồi',
      'addFeedbackTemplate': 'Thêm mẫu phản hồi',
      'detailFeedbackTemplate': 'Chi tiết mẫu phản hồi',
      'templateName': 'Tên mẫu',
      'ratingNumber': 'Số sao đánh giá',
      'ratingNumberList': {
        'rating1': '1 Sao',
        'rating2': '2 Sao',
        'rating3': '3 Sao',
        'rating4': '4 Sao',
        'rating5': '5 Sao'
      },
      'addFeedbackOptionInTemplate': 'Thêm câu phản hồi vào mẫu phản hồi',
      'confirmDeleteFeedbackTemplate': 'Bạn có chắc chắn muốn xoá mẫu phản hồi này?'
    },
    'feedbackOptions': {
      'title': 'Phản hồi',
      'addFeedbackOption': 'Thêm câu phản hồi',
      'detailFeedbackOption': 'Chi tiết câu phản hồi',
      'answerOption': 'Câu phản hồi',
      'confirmDeleteFeedbackOption': 'Bạn có chắc chắn muốn xoá câu phản hồi này?'
    },
    'feedbackOptionAlreadyExistsInTemplate': 'Câu phản hồi đã tồn tại',
    'confirmDeleteFeedbackOptionInTemplate': 'Bạn có chắc chắn muốn xoá câu phản hồi này?'
  }
}
