import moment from 'moment'

const STATIC_FORMAT_DATE = 'DD/MM/YYYY'
const STATIC_FORMAT_DATE_TIME = 'DD/MM/YYYY HH:mm'
const STATIC_FORMAT_DATE_SQL = 'YYYY-MM-DD'
const STATIC_FORMAT_DATE_TIME_ZONE = 'YYYY-MM-DD HH:mm:ss.SSSZZZ'
const STATIC_FORMAT_DATE_TIME_ZONE_T = 'YYYY-MM-DDTHH:mm:ss.sssZ'

/**
 * Format date
 * @param {*} date
 * @param {*} fromFormat
 * @param {*} toFormat
 */
const formatDate = function (date, fromFormat, toFormat) {
  if (date === '') {
    return ''
  }
  return moment(date, fromFormat).format(toFormat)
}

// Get date array between two date
const getDaysBetweenTwoDate = function (startDate, endDate, dateFormat) {
  var dateArray = []
  var currentDate = moment(moment(startDate, dateFormat))
  var stopDate = moment(moment(endDate, dateFormat))
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format(dateFormat))
    currentDate = moment(currentDate).add(1, 'days')
  }
  return dateArray
}

/**
 * Calculate number of days between two dates
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} formatDate
 */
const calculateNumberOfDaysBetweenTwoDates = function (startDate, endDate, formatDate) {
  if (startDate && endDate) {
    var start = moment(startDate, formatDate)
    var end = moment(endDate, formatDate)
    return end.diff(start, 'days')
  }
  return 0
}

export default {
  // Static variable
  STATIC_FORMAT_DATE,
  STATIC_FORMAT_DATE_SQL,
  STATIC_FORMAT_DATE_TIME_ZONE,
  STATIC_FORMAT_DATE_TIME_ZONE_T,
  STATIC_FORMAT_DATE_TIME,
  // Function
  formatDate,
  getDaysBetweenTwoDate,
  calculateNumberOfDaysBetweenTwoDates
}
