<template>
  <div class="text-center">
    <v-snackbar
      :color="getColor()"
      :multi-line="true"
      v-model="snackBarData.isShow"
      style="margin: 16px;"
    >
      {{ snackBarData.message }}
      <v-btn
        text
        dark
        @click="closeSnackbar()"
      >
        {{ $t('common.close') }}
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import ToastType from 'enum/toastType'
import { mapActions } from 'vuex'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      snackBarData: {
        id: '',
        isShow: false,
        message: '',
        timeout: 3000,
        snackbar: true
      }

    }
  },
  mounted () {
    this.snackBarData.id = this.item.id
    this.snackBarData.message = this.item.message
    this.snackBarData.isShow = true
    setTimeout(() => { this.delete() }, 3000)
  },
  methods: {
    closeSnackbar: function () {
      this.delete()
    },
    delete () {
      this.snackBarData.isShow = false
      this.deleteToast(this.item)
    },
    getColor: function () {
      if (this.item.styleType === ToastType.SUCCESS) {
        return 'success'
      } else {
        return 'error'
      }
    },
    ...mapActions(['deleteToast'])
  }
}
</script>
