/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
const Layout = () => import('Views/Layout/ContentLayout')
const Dashboard = () => import('Views/Dashboard')
const Login = () => import('Views/Users/Login')
const PageNotFound = () => import('Views/PageNotFound')
const ActivatedEmail = () => import('Views/Users/ActivatedEmail')
const ForgotPassword = () => import('Views/Users/ForgotPassword')
const ConfirmResetPassword = () => import('Views/Users/ConfirmResetPassword')
const UserProfile = () => import('Views/Users/UserProfile')
const UserList = () => import('Views/Users/UserList')
const ProductList = () => import('Views/Product/ProductList')
const ProductDetail = () => import('Views/Product/ProductDetail')
const SettingsPoint = () => import('Views/Settings/Point')
const CustomerList = () => import('Views/Customer/CustomerList')
const TransactionHistory = () => import('Views/History/TransactionHistory')
const LayoutQrCode = () => import('Views/Product/LayoutQrCode')
const Customization = () => import('Views/Settings/CustomizationTable')
const TemplateEmailCategories = () => import('Views/Notification/TemplateNotificationCategories')
const ProjectTemplateNotificationList = () => import('Views/Notification/ProjectTemplateNotificationList')
const ProjectTemplateNotification = () => import('Views/Notification/ProjectTemplateNotification')
const QuestionList = () => import('Views/Question/QuestionList')
const PrepaidCardList = () => import('Views/PrepaidCard/List')
const PrintsPrepaidCard = () => import('Views/PrepaidCard/PrintsPrepaidCard')
const PrintsQrCodeProduct = () => import('Views/Product/PrintsQrCodeProduct')
const PrintProductItem = () => import('Views/Product/PrintProductItem')
const ReportList = () => import('Views/Report/ReportList')
const Feedback = () => import('Views/Feedback/Feedback')
export default [
  {
    path: '/',
    component: Layout,
    name: 'sideBar.dashboard',
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'sideBar.dashboard',
        component: Dashboard,
        meta: { requiresAuth: true }
      },
      {
        path: '/user-profile/:id',
        component: UserProfile,
        name: 'userProfile.userProfile',
        meta: { requiresAuth: true }
      },
      {
        path: '/user-list',
        component: UserList,
        name: 'sideBar.userManagement',
        meta: { requiresAuth: true }
      },
      {
        path: '/product-list',
        component: ProductList,
        name: 'sideBar.productManagement',
        meta: { requiresAuth: true }
      },
      {
        path: '/product-detail/:id',
        component: ProductDetail,
        name: 'product.productDetail',
        meta: { requiresAuth: true }
      },
      {
        path: '/settings-point',
        component: SettingsPoint,
        name: 'settings.exchangeRate',
        meta: { requiresAuth: true }
      },
      {
        path: '/customer-list',
        component: CustomerList,
        name: 'sideBar.customerManagement',
        meta: { requiresAuth: true }
      },
      {
        path: '/history-list',
        component: TransactionHistory,
        name: 'sideBar.transactionHistory',
        meta: { requiresAuth: true }
      },
      {
        path: '/customization',
        component: Customization,
        name: 'sideBar.generalConfiguration',
        meta: { requiresAuth: true }
      },
      {
        path: '/template-notification-categories',
        component: TemplateEmailCategories,
        name: 'sideBar.configTemplateNotification',
        meta: { requiresAuth: true }
      },
      {
        path: '/template-notification-list/:id',
        component: ProjectTemplateNotificationList,
        name: 'templateNotification.title',
        meta: { requiresAuth: true }
      },
      {
        path: '/template-notification-list/:id',
        component: ProjectTemplateNotificationList,
        name: 'templateNotification.title',
        meta: { requiresAuth: true }
      },
      {
        path: '/create-template-notification/:categoryId',
        component: ProjectTemplateNotification,
        name: 'templateNotification.createTemplateEmail',
        meta: { requiresAuth: true }
      },
      {
        path: '/update-template-notification/:templateId/:categoryId',
        component: ProjectTemplateNotification,
        name: 'templateNotification.updateTemplateEmail',
        meta: { requiresAuth: true }
      },
      {
        path: '/question-and-answer',
        component: QuestionList,
        name: 'sideBar.questionAndAnswer',
        meta: { requiresAuth: true }
      },
      {
        path: '/prepaid-card-list/',
        component: PrepaidCardList,
        name: 'sideBar.prepaidCardManagement',
        meta: { requiresAuth: true }
      },
      {
        path: '/report/',
        component: ReportList,
        name: 'sideBar.reportManagement',
        meta: { requiresAuth: true }
      },
      {
        path: '/feedback',
        component: Feedback,
        name: 'sideBar.feedback',
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/login',
    component: Login,
    name: 'login'
  },
  {
    path: '/account/email_activated/:uid/:token',
    component: ActivatedEmail,
    name: 'activatedEmail'
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'Forgot password'
  },
  {
    path: '/confirm/reset/password/:uid/:token',
    component: ConfirmResetPassword,
    name: 'Confirm reset password'
  },
  {
    path: '/product-qr/:qr_code',
    component: LayoutQrCode,
    name: 'LayoutQrCode'
  },
  {
    path: '/prints-prepaid-card',
    component: PrintsPrepaidCard,
    name: 'PrintsPrepaidCard'
  },
  {
    path: '/prints-qr-code-product',
    component: PrintsQrCodeProduct,
    name: 'PrintsQrCodeProduct',
    meta: { requiresAuth: true }
  },
  {
    path: '/prints-product-item',
    component: PrintProductItem,
    name: 'PrintsQrCodeProduct',
    meta: { requiresAuth: true }
  },
  {
    path: '*',
    component: PageNotFound,
    name: 'NotFound'
  }
]
