import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'

// state
const state = {
  smtp_server: [],
  smtp_project: [],
  smtp_email_server: []
}

// getters
const getters = {
  GET_CONFIG_SMTP_EMAIL_SERVER_DATA: (state, payload) => {
    return state.smtp_email_server
  },
  GET_SMTP_CONFIG_DATA: (state, payload) => {
    return state.smtp_server
  },
  GET_SMTP_PROJECT_DATA: (state, payload) => {
    return state.smtp_project
  }
}

const actions = {
  PUSH_SMTP_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        'api/v1/customizationtable/smtp_config/',
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_SMTP_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/customizationtable/get_smtp_config/',
        payload,
        function (res) {
          context.commit('GET_SMTP_CONFIG', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_SMTP_PROJECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/customizationtable/get_smtp_project/',
        payload,
        function (res) {
          context.commit('GET_SMTP_PROJECT', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PUSH_SMTP_PROJECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        'api/v1/customizationtable/put_smtp_project/',
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_CONFIG_SMTP_EMAIL_SERVER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/customizationtable/get_config_smtp_email_server/',
        payload,
        function (res) {
          context.commit('GET_CONFIG_SMTP_EMAIL_SERVER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PUSH_CONFIG_SMTP_EMAIL_SERVER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        'api/v1/customizationtable/put_config_smtp_email_server/',
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_SMTP_PROJECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        'api/v1/customizationtable/create_smtp_project/',
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ALL_SMTP_PROJECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/customizationtable/get_smtp_project/',
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CONNECT_SMTP_CHECK_PROJECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        'api/v1/customizationtable/connect_smtp_check_project/',
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_SMTP_CONFIG: (state, payload) => {
    state.smtp_server = payload
  },
  GET_SMTP_PROJECT: (state, payload) => {
    state.smtp_project = payload
  },
  GET_CONFIG_SMTP_EMAIL_SERVER: (state, payload) => {
    state.smtp_email_server = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
