import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'

// state
const state = {
  accountList: {}
}

// getters
const getters = {
  GET_ACCOUNT_LIST_DATA: state => {
    return state.accountList
  }
}

// actions
const actions = {
  GET_ACCOUNT_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/v1/user/`,
        payload,
        function (res) {
          context.commit('GET_ACCOUNT_LIST', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_ACCOUNT_LIST: (state, payload) => {
    let account = payload.results
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.accountList = { results: account, paginate: page }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
