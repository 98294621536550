import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'

// state
const state = {
  chargeAndUsePointsReportList: [],
  accountCreatedInYearList: [],
  productUsedReportList: [],
  chargePointsReportList: [],
  usedPointsReportList: []
}

// getters
const getters = {
  GET_CHARGE_AND_USE_POINTS_REPORT_DATA: (state, payload) => {
    return state.chargeAndUsePointsReportList
  },
  GET_ACCOUNT_CREATED_IN_YEAR_REPORT_DATA: (state, payload) => {
    return state.accountCreatedInYearList
  },
  GET_PRODUCT_USED_REPORT_DATA: (state, payload) => {
    return state.productUsedReportList
  },
  GET_CHARGE_POINTS_REPORT_DATA: (state, payload) => {
    return state.chargePointsReportList
  },
  GET_USED_POINTS_REPORT_DATA: (state, payload) => {
    return state.usedPointsReportList
  }
}

const actions = {
  GET_CHARGE_AND_USE_POINTS_REPORT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/dashboard/get_point_report/',
        payload,
        function (res) {
          context.commit('GET_CHARGE_AND_USE_POINTS_REPORT', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_CHARGE_POINTS_REPORT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/dashboard/get_recharge_point_report/',
        payload,
        function (res) {
          context.commit('GET_CHARGE_POINTS_REPORT', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_USED_POINTS_REPORT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/dashboard/get_used_point_report/',
        payload,
        function (res) {
          context.commit('GET_USED_POINTS_REPORT', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_ACCOUNT_CREATED_IN_YEAR_REPORT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/dashboard/get_account_created_in_year/',
        payload,
        function (res) {
          context.commit('GET_ACCOUNT_CREATED_IN_YEAR_REPORT', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_PRODUCT_USED_REPORT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/dashboard/get_product_used_report/',
        payload,
        function (res) {
          context.commit('GET_PRODUCT_USED_REPORT', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_NEW_CUSTOMER_CURRENT_DATE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/dashboard/get_new_user_data/',
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_RECHARGE_AND_USE_POINTS_CURRENT_DATE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/dashboard/get_recharge_and_use_points_data/',
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_REFUND_POINTS_CURRENT_DATE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/dashboard/get_refund_points_data/',
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_CHARGE_AND_USE_POINTS_REPORT: (state, payload) => {
    state.chargeAndUsePointsReportList = payload
  },
  GET_ACCOUNT_CREATED_IN_YEAR_REPORT: (state, payload) => {
    state.accountCreatedInYearList = payload
  },
  GET_PRODUCT_USED_REPORT: (state, payload) => {
    state.productUsedReportList = payload
  },
  GET_CHARGE_POINTS_REPORT: (state, payload) => {
    state.chargePointsReportList = payload
  },
  GET_USED_POINTS_REPORT: (state, payload) => {
    state.usedPointsReportList = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
