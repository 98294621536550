/**
 * Check null
 * @param {*} data
 */
const isNull = function (data) {
  if (data === undefined || data === null) {
    return true
  } else {
    return false
  }
}
/**
 * Check permission
 * @param {*} userInfo
 * @param {*} perm
 */
const hasAction = function (userInfo, perm) {
  let actions = userInfo.actions ? userInfo.actions : []
  if (isSuperAdmin(userInfo)) {
    return true
  }
  for (let i = 0; i < actions.length; i++) {
    let currentPerm = actions[i]
    if (currentPerm === perm) {
      return true
    }
  }
  return false
}
/**
 * Check is super user
 * @param {*} currentUser
 */
const isSuperAdmin = function (currentUser) {
  let userId = currentUser ? currentUser.id : null
  if (userId === 1) {
    return true
  } else {
    return false
  }
}
/**
 * Get first name and last name from full name
 * @param {*} fullName
 */
const getFirstLastName = function (fullName) {
  // Split full name to get last name and first name
  let fullNameArray = fullName.split(' ')
  let firstName = fullNameArray[fullNameArray.length - 1]
  let lastName = ''
  for (let i = 0, size = fullNameArray.length - 1; i < size; i++) {
    lastName += fullNameArray[i] + (i < size - 1 ? ' ' : '')
  }
  return {
    'firstName': firstName,
    'lastName': lastName
  }
}
/**
 * Validate email
 * @param {*} email
 */
const validateEmail = function (email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}
/**
 * Has special character
 * @param {*} string
 */
const hasSpecialCharacter = function (string) {
  // eslint-disable-next-line no-useless-escape
  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
  if (format.test(string)) {
    return true
  } else {
    return false
  }
}
/**
 * Remove space in string
 * @param {*} str
 */
const removeSpaceInString = function (str) {
  return str.replace(/\s/g, '')
}
/**
 * Convert number to money
 * @param {*} number
 */
const convertFormatNumberToMoney = function (number) {
  return Number(number).toFixed(0).replace(/./g, function (c, i, a) {
    return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? '.' + c : c
  })
}
/**
 * Convert number money to normal number
 * @param {*} num
 */
const formatInteger = function (num) {
  num = !isNull(num) ? num : ''
  if (num.length > 3) {
    return num.toString().replace(/[^A-Z0-9]/ig, '')
  }
  return num
}
/**
 * Check entity type
 * @param {*} entityType
 */
const isEntityType = function (entityType) {
  let currentEntityType = sessionStorage.getItem('entityType')
  // eslint-disable-next-line eqeqeq
  if (!isNull(currentEntityType) && currentEntityType == entityType) {
    return true
  }
  return false
}
/**
 * Check has role
 * @param {*} roleId
 * @param {*} user
 */
const hasSystemRole = function (roleId, user) {
  let userRoles = user.group ? user.group : null
  if (userRoles && userRoles.id === roleId) {
    return true
  }
  return false
}
const validatePhone = function (phone) {
  const regex = /^(0[3|5|7|8|9][0-9]{8}|[+]84[3|5|7|8|9][0-9]{8})$/
  return !!regex.test(phone)
}
const validUsername = function (username) {
  var usernameRegex = /^[a-zA-Z0-9]+$/
  return username.match(usernameRegex)
}

/**
 * Check empty string
 * @param {*} value
 */
const isEmptyString = function (value) {
  return typeof value === 'string' && value.trim().length === 0
}

const validateStringIsNull = function (value) {
  if (value == null) {
    return true
  } else {
    return isEmptyString(value)
  }
}

const convertFullName = function (lastName, firstName) {
  if (
    validateStringIsNull(firstName) &&
    validateStringIsNull(lastName)
  ) {
    return ''
  } else {
    if (!validateStringIsNull(firstName) && !validateStringIsNull(lastName)) {
      return lastName + ' ' + firstName
    }
    if (validateStringIsNull(firstName)) {
      return lastName
    } else if (validateStringIsNull(lastName)) {
      return firstName
    }
  }
}
const removeDoubleSlashesFromUrl = function (url) {
  return String(url).replace(/([^:]\/)\/+/g, '$1')
}

const spliceString = function (strText, len) {
  const truncate = (str, len) => str.substring(0, (str + ' ').lastIndexOf(' ', len))
  if (strText.length > len) {
    return truncate(strText, len) + '...'
  } else return strText
}

export default {
  isNull,
  hasAction,
  isSuperAdmin,
  getFirstLastName,
  validateEmail,
  hasSpecialCharacter,
  removeSpaceInString,
  convertFormatNumberToMoney,
  formatInteger,
  hasSystemRole,
  isEntityType,
  validatePhone,
  validUsername,
  validateStringIsNull,
  convertFullName,
  removeDoubleSlashesFromUrl,
  spliceString
}
