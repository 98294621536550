export default {
  'common': {
    'logOut': 'Logout',
    'networkError': 'Network Connection Error',
    'save': 'Save',
    'close': 'Close',
    'success': 'Success',
    'nonumber': 'No',
    'search': 'Search',
    'role': 'Role',
    'state': 'State',
    'actions': 'Actions',
    'noDataAvailable': 'No data available',
    'add': 'Add',
    'email': 'Email',
    'requiredFields': 'This field is required!',
    'confirm': 'Confirm',
    'delete': 'Delete',
    'resetPassword': 'Reset password',
    'notification': 'Notification !',
    'somethingWentWrong': 'Something Went Wrong !',
    'copyUrl': 'Copy url',
    'connect': 'Connect',
    'callBackLogin': 'Go back login',
    'cannotExceed10MB': 'File can not exceed 10mb',
    'update': 'Update',
    'insert': 'Insert',
    'code': 'Code',
    'name': 'Name',
    'point': 'Point',
    'print': 'Print',
    'logo': 'Logo',
    'month': 'Month',
    'date': 'Date',
    'history': 'History',
    'vnd': 'VNĐ'
  },
  'sideBar': {
    'dashboard': 'Dashboard',
    'userManagement': 'User management',
    'productManagement': 'Product management',
    'settingsManagement': 'Settings',
    'customerManagement': 'Customer Management',
    'historyManagement': 'History',
    'transactionHistory': 'Transaction history',
    'generalConfiguration': 'General configuration',
    'configTemplateNotification': 'Config template notification',
    'questionAndAnswer': 'Q & A',
    'prepaidCardManagement': 'Prepaid card management',
    'reportManagement': 'Report management',
    'feedback': 'Feedback'
  },
  'notification': {
    'new': 'New',
    'customer': {
      'rechargedPointsTitle': 'Recharged points successfully',
      'rechargedPoints': 'You have just recharged {0} points into your account. Your total point is {1}'
    }
  },
  'localize': {
    'vietnamese': 'Vietnamese',
    'english': 'English'
  },
  'userProfile': {
    'userProfile': 'User profile',
    'genderType': {
      'other': 'Other',
      'male': 'Male',
      'female': 'Female'
    },
    'gender': 'Gender',
    'birthday': 'Birthday',
    'phone': 'Phone',
    'address': 'Address',
    'firstName': 'First Name',
    'lastName': 'Last Name',
    'userName': 'Username',
    'email': 'Email Address',
    'aboutMe': 'About Me',
    'editProfile': 'Edit Profile',
    'completeYourProfile': 'Complete Your Profile',
    'updateProfile': 'Update Profile',
    'identificationNumber': 'Identification Number',
    'passport': 'Passport'
  },
  'login': {
    'somethingWentWrong': 'Something Went Wrong !',
    'loginInvalid': 'Username or password is incorrect',
    'login': 'Login',
    'username': 'Username',
    'password': 'Password',
    'customerCannotLogin': 'Customer cannot login',
    'inCorrectPassword': 'Incorrect passwork',
    'youHaveNotRegister': 'You have not registered, please register!',
    'userIsDeleted': 'User is deleted',
    'accountIsNotActive': 'User is not active',
    'pleaseActivateAccount': 'Please activate the account in the email to log into the system',
    'Userisnotactiveordeleted': 'User is not active or deleted',
    'forgotPassword': 'Forgot password?',
    'entityInActive': 'Your account management supplier has not been activated!'
  },
  'user': {
    'username': 'UserID',
    'fullname': 'Full name',
    'firstname': 'First Name',
    'lastname': 'Last Name',
    'email': 'Email',
    'lastlogin': 'Last Login',
    'age': 'Age',
    'dob': 'Date of Birth',
    'phone': 'Phone',
    'gender': 'Gender',
    'addnew': 'Add New',
    'add': 'Add',
    'password': 'Password',
    'uppercase': 'Uppercase',
    'lowercase': 'Lowercase',
    'digit': 'Number',
    'group': 'Group',
    'title': 'User List',
    'title_organizer': 'Organizer',
    'privilege': 'Privilege',
    'country': 'Country',
    'is_active': 'Actived',
    'activate_account': 'Activate Account',
    're_send_email': 'Resent Activation',
    'joined_date': 'Joined Date',
    'device': 'Device Number',
    'device_list': 'Mobile Devices Login',
    'app_type': 'App Type',
    'app_type_data': {
      'ios': 'iOS',
      'android': 'Android',
      'other': 'Other'
    },
    'last_access': 'Last Access',
    'last_login': 'Last Login',
    'status': 'Status',
    'status_data': {
      'active': 'Active',
      'inactive': 'In Active'
    },
    'info': 'Info',
    'group_mode': {
      'Admin': 'Administrator',
      'Moderator': 'Moderator',
      'User': 'User',
      'Accountant': 'Accountant'
    },
    'profile': 'User Profile',
    'gender_type': ['Male', 'Female', 'Others'],
    'confirm_pass': {
      'title': 'Confirm Reset Password',
      'content': 'Are you sure want reset password?',
      'btnClose': 'Close',
      'btnConfirm': 'Confirm'
    },
    'addNewUser': 'Add new user',
    'userInfoTitle': 'User info',
    'confirmDeleteUserTitle': 'Are you sure you want to delete this user?',
    'successfulActivation': 'Successful activation',
    'failedActivation': 'Activation failed, please try again later',
    'redirectLoginPage': 'Return to login page in 5 seconds',
    'activeThisAccount': 'Activate this account',
    'address': 'Address',
    'resetPassword': {
      'linkNoLongerValid': 'The reset password link is no longer valid.'
    },
    'changePassword': 'Change password',
    'ChangePasswordModal': {
      'title': 'Change password',
      'guide': 'Use this form to change your password. Once changed, your new password will be in effect next time you login',
      'confirmNewPass': 'Re-enter new password',
      'enterOldPass': 'Enter old password',
      'enterNewPass': 'Enter new password',
      'enterNewPassConfirm': 'Confirm new password',
      'upperCase': 'Uppercase',
      'lowerCase': 'Lowercase',
      'digit': 'Number',
      'compareNewPassAndConfirmPassError': 'Your new password and confirm new password do not match',
      'weakPassError': 'Your password is too weak, try another one',
      'oldPassIncorrect': 'The old password you have entered is incorrect, try again'
    }
  },
  'users': {
    'add': 'User Add',
    'userList': 'User list',
    'fullName': 'Full name',
    'phone': 'Phone',
    'gender': 'gender',
    'username': 'Username',
    'points': 'Point',
    'userDetail': 'User detail',
    'assignedRoles': 'Assigned roles',
    'availableRoles': 'Available roles',
    'email': 'Email',
    'identification': 'Identification',
    'dateCreated': 'Created date',
    'extraInfo': 'Extra information',
    'errorText': {
      'indentAlreadyExist': 'Identification already exist',
      'emailAlreadyExist': 'Email already exist',
      'phoneAlreadyExist': 'Phone already exist'
    },
    'viewDetail': 'View detail',
    'addPoints': 'Add points',
    'refundPoints': 'Refund points',
    'depositAmount': 'Deposit amount',
    'chargePointsModalContent': 'Charge points for user',
    'numOfExchangePoint': 'The number of exchange points is',
    'valueMustBeGreaterThanZero': 'Value must be greater than 0',
    'confirmChargePointsTitle': 'Are you sure you want to charge points for {0}?',
    'state': {
      'active': 'Active',
      'in_active': 'In active'
    },
    'status': {
      'lock': 'Lock'
    },
    'blockedAccount': 'Your account has been locked',
    'blockedCard': 'Your card has been locked',
    'customerRefundPoint': {
      'customerInfo': 'Customer information',
      'customerCurrentPoint': 'current score is',
      'confirmCustomerRefundPointTitle': 'Are you sure you want refund point for customer name {0}?'
    },
    'prepaidCardRefundPoint': {
      'prepaidCardInfo': 'Prepaid card information',
      'prepaidCardCurrentPoint': 'current score is',
      'confirmPrepaidCardRefundPointTitle': 'Are you sure you want refund point for prepaid card name {0}?'
    },
    'exchangedMoney': 'Exchanged money',
    'lockCustomerTitle': 'Are you sure you want to lock this customer?',
    'unlockCustomerTitle': 'Are you sure you want to unlock this customer?',
    'moveCustomerToStaff': {
      'title': 'Move into staff',
      'notiUserHaveNotRefundedPoints': 'Customers have not refunded points. Please refund points before move customers into staff',
      'notiConfirm': 'Are you sure you want move {0} into staff?'
    }
  },
  'decentralizationGroup': {
    'decentralizationGroupList': 'Decentralization group list',
    'addNewDecentralizationGroup': 'Add new decentralization group',
    'updateDecentralizationGroup': 'Update decentralization group',
    'functionalList': 'Functional list',
    'currentFunctionGroup': 'Current function group',
    'functionGroupNotYet': 'Function group not yet available',
    'privilege': 'Privilege',
    'availableGroups': 'Available groups',
    'assignedGroups': 'Assigned groups',
    'onShowModalDeletedecentralizationGroupTitle': 'On Show Modal Delete Decentralization Group Title?'
  },
  'register': {
    'alreadyAccount': 'Already have an account?',
    'createNewOne': 'Create a new one.',
    'notHaveAccount': 'You do not have an account?',
    'accountAlreadyExist': 'Account or email already exists',
    'notSpecialCharacterUsername': 'The username does not contain any special characters',
    'nameMust8CharacterOrMore': 'Username must be 8 characters or more',
    'emailInvalidate': 'Email invalidate',
    'passwordIncorrect': 'Password incorrect',
    'confirmPassword': 'Confirm password',
    'phoneInvalidate': 'Phone invalidate'
  },
  'product': {
    'title': 'Product list',
    'name': 'Name',
    'productImg': 'Image',
    'productPoint': 'Point',
    'description': 'Description',
    'addNewProduct': 'Add new product',
    'productDetail': 'Product detail',
    'confirmDeleteProductTitle': 'Are you sure you want to delete this product?',
    'productImgNote': 'Click the photo to add or update',
    'removeProductImg': 'Remove product image',
    'productInfo': 'Product info',
    'code': 'Product code',
    'numberOfItemPerRow': 'Number of products on row',
    'quantity': 'Quantity'
  },
  'settings': {
    'exchangeRate': 'Exchange rate',
    'numberItemOfPage': {
      'title': 'Number item of page',
      'systemNumberItemOfPage': 'System',
      'userNumberItemOfPage': 'User',
      'value': 'Value'
    }
  },
  'history': {
    'dateAction': 'Date action',
    'personAction': 'Person action',
    'description': 'Description',
    'customerName': 'Customer name',
    'customerEmail': 'Customer email',
    'action': 'Action',
    'transactionHistory': {
      'title': 'Transaction history',
      'transactionType': {
        'addPoints': 'Add points',
        'userPoints': 'Use points',
        'refundPoints': 'Refund points'
      },
      'customer': 'Customer'
    }
  },
  'forgotPassword': {
    'send': 'Send',
    'email': 'Email',
    'newPassword': 'New password',
    'confirmPassword': 'Confirm password',
    'pleaseCheckEmailToChangePassword': 'Please check email to retrieve password !',
    'confirmResetPassword': 'Are you sure you want to reset password for this user?',
    'emailIsNotRegistered': 'Email is not registered'
  },
  'generalConfiguration': {
    'serverSMTP': 'Server SMTP',
    'smtpDomain': 'Domain',
    'smtpUsername': 'Username',
    'smtpPassword': 'Password',
    'smtpConfigurationInfo': 'SMTP configuration information',
    'name': 'Name',
    'alias': 'Alias',
    'description': 'Description',
    'pageSize': 'Page size',
    'subContent': 'Sub content',
    'emailConfiguration': 'Email configuration',
    'port': 'Port',
    'emailAccount': 'Email account',
    'useTLS': 'Use TLS',
    'discount': 'Discount',
    'activeSupplierCode': 'Active supplier code',
    'emailPassword': 'Password',
    'serverMedia': 'Media Server',
    'mediaDomain': 'Domain',
    'mediaUsername': 'User Name',
    'mediaPassword': 'Password',
    'mediaProject': 'Media Project',
    'mediaProjectName': 'Project Name',
    'mediaPageSize': 'Page Size',
    'mediaDescription': 'Description'
  },
  'customization': {
    'configOutgoingEmail': 'Configure outgoing email',
    'projectDefault': 'Project default',
    'errorMessage': {
      'projectIdIsNone': 'Please select a project to connect',
      'notFoundProject': 'Your project could not be found',
      'infoConnectIncorrect': 'Information is not correct',
      'cannotConnectToServer': 'Can not connect to server'
    },
    'usedEmailOfSystem': 'Used email of system'
  },
  'templateNotification': {
    'title': 'Template notification',
    'category': {
      'title': 'Template notification category',
      'list': 'Template notification category list',
      'table': {
        'headerName': 'Category name',
        'headerNumberOfTemplate': 'Number of template'
      },
      'viewDetail': 'View detail'
    },
    'usedThisTemplate': 'Use this template',
    'name': 'Name',
    'templateTitle': 'Title',
    'templateContent': 'Content',
    'usedDefault': 'Used default',
    'list': 'Template notification list',
    'createNew': 'Create new template',
    'createTemplateEmail': 'Create template notification',
    'updateTemplateEmail': 'Update template notification',
    'info': 'Template notification info',
    'chooseValue': 'Choose value',
    'copy': 'Copy to clipboard',
    'previewTemplate': 'Preview template',
    'chooseTemplateDefault': 'Choose template default',
    'modePreview': 'Preview mode',
    'chooseThisTemplate': 'Choose this template',
    'confirmDeleteTemplate': 'Are you sure you want to delete this template?',
    'state': {
      'active': 'Active',
      'in_active': 'In active'
    }
  },
  'dashboard': {
    'byDate': 'By date',
    'byMonth': 'By month',
    'chargeAndUsePointsReport': {
      'title': 'Statistics charged and used points',
      'labels': {
        'chargePoints': 'Charge points',
        'usePoints': 'Use points'
      }
    },
    'chargePointsReport': {
      'title': 'Statistics for points',
      'labels': {
        'customerCharge': 'Charge point in app',
        'userghostCharge': 'Charge point in card'
      },
      'totalChargePoints': 'Total charge points'
    },
    'usePointsReport': {
      'title': 'Statistics use points',
      'labels': {
        'customerUsePoints': 'Use points in app',
        'ghostuserUsePoints': 'Use points in card'
      },
      'totalUsedPoints': 'Total points used'
    },
    'accountCreatedInYearReport': {
      'title': 'Statistics of the number of accounts created',
      'numberOfAccount': 'Number',
      'labels': {
        'createCustomer': 'Account create in app',
        'activePrepaidCard': 'Prepaid card activated'
      },
      'accountAndCardActive': 'Total number of accounts and activation cards'
    },
    'totalProductUsedReport': {
      'title': 'Statistics of the number of products used',
      'labels': {
        'customer': 'Use on the app',
        'prepaidCard': 'Use on cards'
      },
      'totalProductUsed': 'Total number of products used'
    },
    'newCustomerReport': {
      'title': 'Customer joined today'
    },
    'rechargeAndUsePoints': {
      'title': 'Today points usage'
    },
    'refundPointsReport': {
      'title': 'Today points refund summary'
    }
  },
  'questionAndAnswer': {
    'title': 'Question and answer',
    'addQuestion': 'Add Q&A',
    'detailQuestion': 'Detail Q&A',
    'questionContent': 'Content questions',
    'questionAnswer': 'Answer',
    'confirmDeleteQuestion': 'Are you sure you want to delete this question?',
    'orderPriority': {
      'moveToUp': 'Move to up',
      'moveToDown': 'Move to down',
      'moveToFirst': 'Move to first',
      'moveToLast': 'Move to last'
    }
  },
  'prepaidCard': {
    'title': 'Prepaid card',
    'name': 'Name card',
    'code': 'Code card',
    'list': 'Prepaid card list',
    'add': 'Add new prepaid card',
    'rechargePointForCardCode': 'Recharge point for card code',
    'lockCard': 'Lock',
    'unlockCard': 'Unlock',
    'lockCardTitle': 'Are you sure you want to lock this card?',
    'unlockCardTitle': 'Are you sure you want to unlock this card?',
    'additionalInfo': 'More information of the card',
    'mainTitle': 'Main title',
    'subTitle': 'Sub title',
    'hotline': 'Hotline',
    'printCard': 'Print prepaid card',
    'printCardSelected': 'Print prepaid card selected',
    'printAllPrepaidCard': 'Print all prepaid card',
    'note': 'Please take care of the card, fill in personal information',
    'support': 'Support',
    'username': 'Username',
    'points': 'Points',
    'rechargePointsInBulk': 'Recharge points in bulk',
    'plsChoosePrepaidCardNeedRecharge': 'Please choose prepaid card to recharge',
    'rechargePointsFor': 'Recharge points for',
    'prepaidCard': 'prepaid cards',
    'status': {
      'activated': 'Activated',
      'inactive': 'Inactive'
    },
    'errorPrepaidCardInActive': 'The prepaid card has not been activated',
    'rangeQueryTitle': 'Number of item per print page',
    'lockAndUnlockTitle': 'Lock/Unlock',
    'lockCardSelect': 'Lock card selected',
    'unlockCardSelect': 'Unlock card selected',
    'selectPrepaidCardLockOrUnlock': 'Please select card',
    'lockCardSelectTitle': 'Are you sure you want to lock the card selected?',
    'unlockCardSelectTitle': 'Are you sure you want to unlock the card selected?'
  },
  'report': {
    'time': 'Time',
    'timeList': {
      'yesterday': 'Yesterday',
      'today': 'Today',
      '7daysAgo': '7 days ago',
      '30daysAgo': '30 days ago',
      'selectMonth': 'Select month',
      'selectDate': 'Select date'
    },
    'labelTextList': {
      'numberOfUserActive': 'Number of new user active',
      'numberOfRechargePoints': 'number of recharge points',
      'numberOfPointsUsed': 'Number of points used',
      'numberOfRefundPoints': 'Number of refund points',
      'numberOfUsersReturn': 'Number of users return',
      'numberOfPrepaidCardActive': 'Number of prepaid card active'
    }
  },
  'feedback': {
    'feedbackTemplate': {
      'title': 'Feedback template',
      'addFeedbackTemplate': 'Add feedback template',
      'detailFeedbackTemplate': 'feedback template detail',
      'templateName': 'feedback template name',
      'ratingNumber': 'Rating number',
      'ratingNumberList': {
        'rating1': '1 Star',
        'rating2': '2 Star',
        'rating3': '3 Star',
        'rating4': '4 Star',
        'rating5': '5 Star'
      },
      'addFeedbackOptionInTemplate': 'Add feedback option in template',
      'confirmDeleteFeedbackTemplate': 'Are you sure you want to delete this feedback template?'
    },
    'feedbackOptions': {
      'title': 'Feedback option',
      'addFeedbackOption': 'add feedback option',
      'detailFeedbackOption': 'Feedback option detail',
      'answerOption': 'answer option',
      'confirmDeleteFeedbackOption': 'Are you sure you want to delete this feedback?'
    },
    'feedbackOptionAlreadyExistsInTemplate': 'Feedback option already exist in template',
    'confirmDeleteFeedbackOptionInTemplate': 'Are you sure you want to remove this feedback?'
  }
}
