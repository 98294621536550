<template>
  <div class="text-xs-center">
    <v-pagination
      v-model="currentPage"
      :length="pages ? pages : 1"
      :total-visible="rowPerPage > 9 ? 9 : rowPerPage"
      @input="onPageChange"
    />
  </div>
</template>

<script>

export default {
  props: {
    total: {
      type: Number,
      default: () => { return 1 }
    },
    rowPerPage: {
      type: Number,
      default: () => { return 1 }
    }
  },
  data: () => ({
    currentPage: 1
  }),

  computed: {
    pages () {
      if (this.rowPerPage == null || this.total == null) {
        return 0
      }
      return this.total
    }
  },
  methods: {
    onPageChange: function () {
      this.$emit('onPageChange', this.currentPage)
    }
  }
}
</script>
