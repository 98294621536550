<template>
  <v-toolbar
    id="core-toolbar"
    app
    flat
    prominent
    style="background: #eee;"
  >
    <div class="v-toolbar-title">
      <v-toolbar-title
        class="tertiary--text font-weight-light"
      >
        <v-btn
          class="default v-btn--simple"
          dark
          icon
          @click.stop="onClickBtn"
        >
          <v-icon color="success">mdi-view-list</v-icon>
        </v-btn>
        {{ $t(title) }}
      </v-toolbar-title>
    </div>

    <v-spacer />
    <v-toolbar-items>
      <v-flex
        align-center
        layout
        py-2
      >
        <!-- Notification -->
        <v-menu
          open-on-hover
          bottom
          left
          offset-y
          transition="slide-y-transition"
        >
          <router-link
            v-ripple
            slot="activator"
            class="toolbar-items"
            to=""
          >
            <v-badge
              color="error"
              overlap
            >
              <template slot="badge">
                {{ $t('notification.new') }}
              </template>
              <v-icon color="tertiary">mdi-bell</v-icon>
            </v-badge>
          </router-link>
          <v-card>
            <v-list
              dense
              style="max-height: 200px; overflow: auto;">
              <v-list-tile
                v-for="(notification, index) in notifications"
                :key="notification.title + index"
                class="break-line"
                style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid #e5e5e5;"
                @click="goScreenVoucher($event)"
              >
                <v-list-tile-title
                  v-text="notification.title"
                />
                <v-list-tile-subTitle
                  v-text="notification.date_created"
                />
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu>
        <!-- Language -->
        <v-menu
          open-on-hover
          bottom
          left
          offset-y
          transition="slide-y-transition"
        >
          <router-link
            v-ripple
            slot="activator"
            class="toolbar-items"
            to=""
          >
            <v-badge
              color="error"
              overlap
            >
              <span style="text-transform: uppercase; color: rgba(0,0,0,.87);">{{ selectedLocale.locale }}</span>
            </v-badge>
          </router-link>
          <v-card>
            <v-list dense>
              <v-list-tile
                v-for="language in languages"
                :key="language.locale"
                @click="changeLanguage(language)"
              >
                <v-list-tile-title
                  v-text="$t(language.name)"
                />
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu>
        <!-- Dashboard -->
        <router-link
          v-ripple
          class="toolbar-items"
          to="/dashboard"
        >
          <v-icon color="tertiary">mdi-view-dashboard</v-icon>
        </router-link>
        <!-- User Profile -->
        <v-menu
          :close-on-content-click="false"
          :dark="dark"
          :nudge-width="350"
          open-on-hover
          bottom
          left
          transition="slide-y-transition"
          offset-y>
          <template
            v-slot:activator="{ on }"
            style="float: right;">
            <div
              v-ripple
              class="toolbar-items"
              style="cursor: pointer"
              v-on="on"
            >
              <v-icon color="tertiary">mdi-account</v-icon>
            </div>
          </template>
          <v-card>
            <v-list>
              <v-list-tile avatar>
                <!-- Avatar -->
                <div
                  v-if="!validateStringIsNull(currentAccount.avatarUrl)"
                  class="mr-2">
                  <avatar
                    :src="currentAccount.avatarUrl"/>
                </div>
                <div
                  v-else
                  class="mr-2">
                  <avatar :username="currentAccount.fullName" />
                </div>
                <!-- Content -->
                <v-list-tile-content>
                  <v-list-tile-title>{{ currentAccount.fullName }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ currentAccount.email }}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile>
                  <v-list-tile-title class="group-style">{{ currentAccount.group }}</v-list-tile-title>
                </v-list-tile>
              </v-list-tile>
            </v-list>
            <v-divider style="margin: 0;"/>
            <!-- Dark mode -->
            <!-- <v-list>
              <v-list-tile>
                <v-list-tile-action>
                  <v-switch
                    v-model="dark"
                    color="success"/>
                </v-list-tile-action>
                <v-list-tile-title>{{ $t('common.darkMode') }}</v-list-tile-title>
              </v-list-tile>
            </v-list> -->
            <!-- <v-divider style="margin: 0;"/> -->
            <v-list>
              <!-- Decentralization -->
              <!-- <v-list-tile
                v-if="isAdminRole()"
                @click="onGoToDecentralization()">
                <v-list-tile-action>
                  <v-icon
                    color="primary">mdi-scale-balance</v-icon>
                </v-list-tile-action>
                <v-list-tile-title>{{ $t('sideBar.decentralization') }}</v-list-tile-title>
              </v-list-tile> -->
              <!-- Account -->
              <!-- <v-list-tile
                v-if="isAdminRole()"
                @click="onGoToAccount()">
                <v-list-tile-action>
                  <v-icon
                    color="primary">mdi-account-edit</v-icon>
                </v-list-tile-action>
                <v-list-tile-title>{{ $t('sideBar.account') }}</v-list-tile-title>
              </v-list-tile> -->
              <!-- Profile -->
              <v-list-tile @click="onGoUserProfile()">
                <v-list-tile-action>
                  <v-icon
                    color="primary">mdi-account</v-icon>
                </v-list-tile-action>
                <v-list-tile-title>{{ $t('userProfile.userProfile') }}</v-list-tile-title>
              </v-list-tile>
              <!-- Entity info -->
              <!-- <v-list-tile
                v-if="!isAdmin() && !isSuperAdmin()"
                @click="onOpenEntityInfo()">
                <v-list-tile-action>
                  <v-icon
                    color="primary">mdi-home-city</v-icon>
                </v-list-tile-action>
                <v-list-tile-title>{{ entityType == EntityType.SUPPLIER ? $t('entity.supplierInfo') : $t('entity.agentInfo') }}</v-list-tile-title>
              </v-list-tile> -->
            </v-list>
            <v-divider style="margin: 0;"/>
            <!-- Logout -->
            <v-list>
              <v-list-tile @click="onLogOut()">
                <v-list-tile-action>
                  <v-icon
                    color="error">mdi-logout</v-icon>
                </v-list-tile-action>
                <v-list-tile-title class="font-red-color">{{ $t('common.logOut') }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu>
      </v-flex>
    </v-toolbar-items>
  </v-toolbar>
</template>
<script>
import {
  mapMutations,
  mapGetters,
  mapActions
} from 'vuex'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
import Avatar from 'vue-avatar'
import functionUtils from 'utils/functionUtils'
export default {
  components: {
    Avatar
  },
  data: () => ({
    notifications: [
      {
        title: 'Bạn chưa có thông báo mới nào !',
        date_created: new Date()
      }
    ],
    title: null,
    responsive: false,
    fav: false,
    currentAccount: {
      fullName: 'No data',
      group: 'No data',
      email: 'No data',
      avatarUrl: ''
    },
    dark: false
  }),
  computed: {
    ...mapGetters(['languages', 'selectedLocale', 'GET_CURRENT_ACCOUNT']),
    role: function () {
      return this.GET_CURRENT_ACCOUNT.id
    }
  },
  watch: {
    '$route' (val) {
      this.title = val.name
    },
    GET_CURRENT_ACCOUNT: function () {
      this.currentAccount.fullName = this.GET_CURRENT_ACCOUNT.lastname + ' ' + this.GET_CURRENT_ACCOUNT.firstname
      this.currentAccount.group = this.GET_CURRENT_ACCOUNT ? (this.GET_CURRENT_ACCOUNT.group && this.GET_CURRENT_ACCOUNT.group.name) : ''
      this.currentAccount.email = this.GET_CURRENT_ACCOUNT && this.GET_CURRENT_ACCOUNT.email
      this.currentAccount.avatarUrl = this.GET_CURRENT_ACCOUNT && this.GET_CURRENT_ACCOUNT.url_avatar
      // this.entityType = sessionStorage.getItem('entityType')
    }
  },
  created () {
    // let entityId = sessionStorage.getItem('entityId')
    // this.entityType = sessionStorage.getItem('entityType')
    // let filter = {
    //   params: {
    //     entityId: entityId
    //   }
    // }
    // this.GET_VOUCHER_DISTRIBUTION_NOTIFICATION_LIST(filter)
  },
  mounted () {
    // Responsive inverted
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    // let entityName = sessionStorage.getItem('entityName')
    // if (!functionUtils.isNull(entityName)) {
    //   this.entityName = entityName
    // } else {
    //   this.entityName = 'Bạn chưa chọn dự án'
    // }
    this.title = this.$route.name
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    validateStringIsNull: function (value) {
      if (value !== undefined) {
        return functionUtils.validateStringIsNull(value)
      }
      return false
    },
    /**
     * Go to the user profile
     */
    onGoUserProfile: function () {
      this.$router.push({
        name: 'userProfile.userProfile',
        params: { id: this.GET_CURRENT_ACCOUNT.id }
      })
      // Todo
    },
    /**
     * Go screen voucher
     */
    goScreenVoucher: function (event) {
      event.preventDefault()
      this.$router.push({
        path: '/'
      })
    },
    /**
     * On go to account
     */
    onGoToAccount: function () {
      this.$router.push({
        name: 'sideBar.account'
      })
    },
    /**
     * Log out
     */
    onLogOut: function () {
      let token = isCookieEnabled() ? getCookie('stock_token') : null
      this.LOGOUT(token).then(
        function () {
          this.$router.push({
            name: 'login'
          })
        }.bind(this)
      ).catch(
        function () {
          this.$router.push({
            name: 'login'
          })
        }.bind(this)
      )
    },
    ...mapMutations(['setDrawer', 'toggleDrawer']),
    /**
     * Change language
     */
    changeLanguage (language) {
      this.$i18n.locale = language.locale
      this.$store.dispatch('changeLanguage', language)
    },
    onClickBtn () {
      this.setDrawer(!this.$store.state.app.drawer)
    },
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    },
    ...mapActions(['LOGOUT', 'GET_VOUCHER_DISTRIBUTION_NOTIFICATION_LIST'])
  }
}
</script>

<style lang="scss" scoped>
  #core-toolbar a {
    text-decoration: none;
  }
  .group-style {
    border: 1px solid #f44336!important;
    padding: 2px 5px 25px 5px;
    border-radius: 5px;
    background-color: #f44336!important;
    color: white;
    font-size: 13px;
  }
  .font-red-color {
    color: #f44336 !important;
  }
  .v-toolbar .v-toolbar__content .v-toolbar__items .toolbar-items:hover {
    background: #e5e5e5;
  }
  .arrow {
    border: solid #495057;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
  .down {
    margin-bottom: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  /deep/.break-line .v-list__tile {
    display: block;
  }
</style>
