/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'

// Routes
import paths from './paths'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
Vue.use(Router)

// Create a new router
const router = new Router({
  base: process.env.VUE_APP_BASE_PATH,
  mode: 'history',
  routes: paths
})
const isLogged = () => loadSessionFromCookie()
const loadSessionFromCookie = () => {
  const token = isCookieEnabled() ? getCookie('stock_token') : null
  if (token) {
    return true
  } else {
    return false
  }
}
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!isLogged()) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
