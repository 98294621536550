import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'

// state
const state = {
  mediaServer: [],
  mediaProject: [],
  tokenMedia: []
}

// getters
const getters = {
  GET_MEDIA_CONFIG_DATA: (state, payload) => {
    return state.mediaServer
  },
  GET_MEDIA_PROJECT_DATA: (state, payload) => {
    return state.mediaProject
  },
  GET_TOKEN_MEDIA_SERVER_DATA: (state, payload) => {
    return state.tokenMedia
  }
}

const actions = {
  PUSH_MEDIA_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        'api/v1/customizationtable/media_config/',
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_MEDIA_CONFIG: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/customizationtable/get_media_config/',
        payload,
        function (res) {
          context.commit('GET_MEDIA_CONFIG', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_MEDIA_PROJECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/customizationtable/get_media_project/',
        payload,
        function (res) {
          context.commit('GET_MEDIA_PROJECT', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  PUSH_MEDIA_PROJECT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        'api/v1/customizationtable/put_media_project/',
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_TOKEN_MEDIA_SERVER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/customizationtable/get_token_media_server/',
        payload,
        function (res) {
          context.commit('GET_TOKEN_MEDIA_SERVER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_MEDIA_CONFIG: (state, payload) => {
    state.mediaServer = payload
  },
  GET_MEDIA_PROJECT: (state, payload) => {
    state.mediaProject = payload
  },
  GET_TOKEN_MEDIA_SERVER: (state, payload) => {
    state.tokenMedia = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
