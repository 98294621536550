const NETWORK_ERROR = 'Network Error'
const GenderList = [
  {
    text: 'userProfile.genderType.male',
    value: 1
  },
  {
    text: 'userProfile.genderType.female',
    value: 2
  },
  {
    text: 'userProfile.genderType.other',
    value: 3
  }
]
const DocumentTypeList = [
  {
    text: 'Pdf',
    value: 1
  },
  {
    text: 'Video',
    value: 2
  },
  {
    text: 'Doc',
    value: 3
  }
]
const EVoucherType = [
  {
    text: 'Voucher',
    value: 1
  },
  {
    text: 'Coupon',
    value: 2
  }
]
const EVoucherValue = [
  {
    text: 'evoucher.issuance.price',
    value: 1
  },
  {
    text: 'evoucher.issuance.percent',
    value: 2
  }
]
const EVoucherStatus = [
  {
    text: 'evoucher.status.in_active',
    value: 0
  },
  {
    text: 'evoucher.status.using_expired',
    value: 1
  },
  {
    text: 'evoucher.status.activated',
    value: 3
  },
  {
    text: 'evoucher.status.used',
    value: 4
  },
  {
    text: 'evoucher.status.distribution_expired',
    value: 7
  },
  {
    text: 'evoucher.status.locked',
    value: 6
  },
  {
    text: 'evoucher.status.waiting',
    value: 5
  }
]
const EVoucherIssuanceStatus = [
  {
    text: 'user.status_data.active',
    value: 1
  },
  {
    text: 'user.status_data.inactive',
    value: 0
  }
]
const EVoucherAssignStatus = [
  {
    text: 'evoucher.assigned',
    value: 1
  },
  {
    text: 'evoucher.notAssignedYet',
    value: 2
  }
]

const EntityStatus = [
  {
    text: 'user.status_data.active',
    value: 1
  },
  {
    text: 'user.status_data.inactive',
    value: 0
  }
]

const ObjectSettingKey = {
  DISCOUNT: 'discount',
  SUPPLIER_ACTIVE_CODE: 'supplier_active_code'
}

const EVoucherReportStatus = [
  {
    text: 'evoucher.assigned',
    value: 1
  },
  {
    text: 'evoucher.notAssignedYet',
    value: 2
  },
  {
    text: 'voucherReport.notUseYet',
    value: 3
  },
  {
    text: 'voucherReport.used',
    value: 4
  }
]

const GetCustomerType = [
  {
    text: 'customer.allCustomer',
    value: 1
  },
  {
    text: 'customer.myCustomer',
    value: 2
  }
]

const ScheduleExecuteList = [
  {
    text: 'evoucher.batchJob.scheduleExecuteList.everyMinute',
    value: 1
  },
  {
    text: 'evoucher.batchJob.scheduleExecuteList.every5Minute',
    value: 2
  },
  {
    text: 'evoucher.batchJob.scheduleExecuteList.dailyAtMidnight',
    value: 3
  }
]

const DistributionStatus = [
  {
    text: 'user.status_data.distribution',
    value: 1
  },
  {
    text: 'user.status_data.noDistribution',
    value: 0
  }
]

const PayStatus = [
  {
    text: 'createEVoucher.prepaid',
    value: true
  },
  {
    text: 'createEVoucher.unpaid',
    value: false
  }
]
const DOMAIN_BASE_PATH_ADMIN = window.location.protocol + '//' + window.location.host + process.env.VUE_APP_BASE_PATH
const CUSTOMER_URL = process.env.VUE_APP_CUSTOMER_URL

const SupplierAccessCodeStatus = [
  {
    value: 0, text: 'supplierAccessCode.statusData.enable'
  },
  {
    value: 1, text: 'supplierAccessCode.statusData.disable'
  },
  {
    value: 2, text: 'supplierAccessCode.statusData.expired'
  },
  {
    value: 3, text: 'supplierAccessCode.statusData.deleted'
  }
]

const adminGroup = [
  { value: 1, text: 'user.group_mode.Admin' },
  { value: 2, text: 'user.group_mode.Moderator' },
  { value: 4, text: 'user.group_mode.Accountant' }
]
const customerGroup = [
  { value: 3, text: 'user.group_mode.User' }
]
const customerTypeList = [
  { value: 1, text: 'user.group_mode.User' },
  { value: 2, text: 'prepaidCard.title' }
]

const reportTimeList = [
  { value: 2, text: 'report.timeList.today' },
  { value: 1, text: 'report.timeList.yesterday' },
  { value: 3, text: 'report.timeList.7daysAgo' },
  { value: 4, text: 'report.timeList.30daysAgo' },
  { value: 5, text: 'report.timeList.selectMonth' },
  { value: 6, text: 'report.timeList.selectDate' }
]

const reportLabelTextList = [
  { value: 1, text: 'report.labelTextList.numberOfUserActive' },
  { value: 2, text: 'report.labelTextList.numberOfRechargePoints' },
  { value: 3, text: 'report.labelTextList.numberOfPointsUsed' },
  { value: 4, text: 'report.labelTextList.numberOfRefundPoints' },
  { value: 5, text: 'report.labelTextList.numberOfUsersReturn' },
  { value: 6, text: 'report.labelTextList.numberOfPrepaidCardActive' }
]

const transactionTypeList = [
  { value: 1, text: 'history.transactionHistory.transactionType.userPoints' },
  { value: 2, text: 'history.transactionHistory.transactionType.addPoints' },
  { value: 3, text: 'history.transactionHistory.transactionType.refundPoints' }
]

const prepaidCardStatusList = [
  { value: 1, text: 'prepaidCard.status.activated' },
  { value: 2, text: 'prepaidCard.status.inactive' }
]

const feedbackTemplateRatingList = [
  { value: 1, text: 'feedback.feedbackTemplate.ratingNumberList.rating1' },
  { value: 2, text: 'feedback.feedbackTemplate.ratingNumberList.rating2' },
  { value: 3, text: 'feedback.feedbackTemplate.ratingNumberList.rating3' },
  { value: 4, text: 'feedback.feedbackTemplate.ratingNumberList.rating4' },
  { value: 5, text: 'feedback.feedbackTemplate.ratingNumberList.rating5' }
]
export default {
  NETWORK_ERROR,
  GenderList,
  DocumentTypeList,
  EVoucherType,
  EVoucherValue,
  EVoucherStatus,
  EVoucherAssignStatus,
  EntityStatus,
  ObjectSettingKey,
  EVoucherIssuanceStatus,
  EVoucherReportStatus,
  GetCustomerType,
  ScheduleExecuteList,
  DistributionStatus,
  PayStatus,
  DOMAIN_BASE_PATH_ADMIN,
  CUSTOMER_URL,
  SupplierAccessCodeStatus,
  adminGroup,
  customerGroup,
  customerTypeList,
  reportTimeList,
  reportLabelTextList,
  transactionTypeList,
  prepaidCardStatusList,
  feedbackTemplateRatingList
}
