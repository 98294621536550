import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'

// state
const state = {
  questionAndAnswer: []
}
// getters
const getters = {
  GET_QUESTION_AND_ANSWER_DATA: (state, payload) => {
    return state.questionAndAnswer
  }
}

const actions = {
  GET_QUESTION_AND_ANSWER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/question_and_answer/',
        payload,
        function (res) {
          context.commit('GET_QUESTION_AND_ANSWER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_QUESTION_AND_ANSWER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/question_and_answer/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_QUESTION_AND_ANSWER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/question_and_answer/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_QUESTION_AND_ANSWER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `/api/v1/question_and_answer/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_PRIORITY_QUESTION_AND_ANSWER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/question_and_answer/update_priority_question/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_QUESTION_AND_ANSWER: (state, payload) => {
    let questionAndAnswer = payload.results !== undefined ? payload.results : payload
    let page = {
      totalPage: payload.num_pages,
      currentPage: payload.page,
      pageSize: payload.page_size,
      perPage: payload.per_page
    }
    state.questionAndAnswer = payload.results !== undefined ? { results: questionAndAnswer, paginate: page } : questionAndAnswer
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
