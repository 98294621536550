import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'

// state
const state = {
  feedbackTemplate: [],
  feedbackOption: [],
  feedbackOptionInTemplate: [],
  feedbackOptionNoPage: []
}

// getters
const getters = {
  GET_FEEDBACK_TEMPLATE_DATA: (state, payload) => {
    return state.feedbackTemplate
  },
  GET_FEEDBACK_OPTION_DATA: (state, payload) => {
    return state.feedbackOption
  },
  GET_FEEDBACK_OPTION_NO_PAGE_DATA: (state, payload) => {
    return state.feedbackOptionNoPage
  },
  GET_FEEDBACK_OPTION_IN_TEMPLATE_DATA: (state, payload) => {
    return state.feedbackOptionInTemplate
  }
}

const actions = {
  GET_FEEDBACK_TEMPLATE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/feedback/',
        payload,
        function (res) {
          context.commit('GET_FEEDBACK_TEMPLATE', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_FEEDBACK_TEMPLATE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/feedback/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_FEEDBACK_TEMPLATE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `api/v1/feedback/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_FEEDBACK_TEMPLATE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `api/v1/feedback/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_FEEDBACK_OPTION_IN_TEMPLATE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/feedback/get_feedback_option_in_template/',
        payload,
        function (res) {
          context.commit('GET_FEEDBACK_OPTION_IN_TEMPLATE', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ADD_FEEDBACK_OPTION_IN_TEMPLATE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/feedback/add_feedback_option_in_template/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_FEEDBACK_OPTION_IN_TEMPLATE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/feedback/remove_feedback_option/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  //  feedback options
  GET_FEEDBACK_OPTION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        'api/v1/feedback_options/',
        payload,
        function (res) {
          if (res.data.page !== undefined) {
            context.commit('GET_FEEDBACK_OPTION', res.data)
          } else {
            context.commit('GET_FEEDBACK_OPTION_NO_PAGE', res.data)
          }
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CREATE_FEEDBACK_OPTION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/v1/feedback_options/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_FEEDBACK_OPTION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `api/v1/feedback_options/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  DELETE_FEEDBACK_OPTION: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.deleteRequest(
        `api/v1/feedback_options/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
  GET_FEEDBACK_TEMPLATE: (state, payload) => {
    let feedbackTemplate = payload.results !== undefined ? payload.results : payload
    if (payload.results !== undefined) {
      let page = {
        totalPage: payload.num_pages,
        currentPage: payload.page,
        pageSize: payload.page_size,
        perPage: payload.per_page
      }
      state.feedbackTemplate = { results: feedbackTemplate, paginate: page }
    } else {
      state.feedbackTemplate = payload
    }
  },
  GET_FEEDBACK_OPTION: (state, payload) => {
    let feedbackOption = payload.results !== undefined ? payload.results : payload
    if (payload.results !== undefined) {
      let page = {
        totalPage: payload.num_pages,
        currentPage: payload.page,
        pageSize: payload.page_size,
        perPage: payload.per_page
      }
      state.feedbackOption = { results: feedbackOption, paginate: page }
    } else {
      state.feedbackOption = payload
    }
  },
  GET_FEEDBACK_OPTION_NO_PAGE: (state, payload) => {
    state.feedbackOptionNoPage = payload
  },
  GET_FEEDBACK_OPTION_IN_TEMPLATE: (state, payload) => {
    let feedbackOptionInTemplate = payload.results !== undefined ? payload.results : payload
    if (payload.results !== undefined) {
      let page = {
        totalPage: payload.num_pages,
        currentPage: payload.page,
        pageSize: payload.page_size,
        perPage: payload.per_page
      }
      state.feedbackOptionInTemplate = { results: feedbackOptionInTemplate, paginate: page }
    } else {
      state.feedbackOptionInTemplate = payload
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
